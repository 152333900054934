<template>
    <q-layout view="lHh Lpr fff">
        <q-page class="window-height window-width row justify-center items-center"
            style="background: linear-gradient(rgba(225,245,255,.3), rgba(225,245,255,.3));">
            <div class="column q-pa-lg">
                <q-card class="q-py-sm q-px-md" bordered flat>
                    <q-img
                        src="../assets/img/logofamilia.jpg"
                        class="logoimage"
                        style="max-width: 100px;"
                    /> <br>
                </q-card>
            </div>
            <div class="column q-pa-lg">
                <div class="row">
                    <q-card class="shadow-2" bordered flat style="width:400px;">
                        <q-card-section>
                            <q-form class="q-px-sm q-pt-sm">
                                <q-input ref="email" square filled clearable v-model="email" type="email" lazy-rules
                                    :rules="[this.required, this.isEmail, this.short]" label="Correo electrónico">
                                    <template v-slot:prepend>
                                        <q-icon name="email" />
                                    </template>
                                </q-input>
                                <q-input ref="password" square filled clearable v-model="password" :type="passwordFieldType"
                                    lazy-rules :rules="[this.required, this.short]" label="Contraseña">
                                    <template v-slot:prepend>
                                        <q-icon name="lock" />
                                    </template>
                                    <template v-slot:append>
                                        <q-icon :name="visibilityIcon" @click="switchVisibility"
                                            class="cursor-pointer" />
                                    </template>
                                </q-input>
                            </q-form>
                        </q-card-section>

                        <q-card-actions class="q-px-lg">
                            <q-btn @click="onLogin" :loading="loading" unelevated size="lg" color="primary" class="full-width text-white" :label="btnLabel" />
                        </q-card-actions>
                        <q-card-section class="text-center q-pa-sm">
                            <!-- <p class="text-grey-6">¿Olvidaste tu contraseña?</p> -->
                        </q-card-section>
                    </q-card>
                </div>
            </div>
        </q-page>
    </q-layout>
</template>
<script>
import axiosServices from '@/services/axiosServices';
export default {
    components: {},
    data(){
        return {
            loading: false,
            nKey: 0,
            email: '',
            password: '',
            passwordFieldType: 'password',
            btnLabel: 'INICIAR SESIÓN',
            visibility: false,
            visibilityIcon: 'visibility',
        }
  },
    
   methods: {
    required (val) {
      return  (val && val.length > 0 || 'Este campo es requerido')
    },
    short(val) {
      return  (val && val.length > 3 || 'El texto debe contener mínimo 3 carácteres')
    },
    isEmail (val) {
       const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/
       return (emailPattern.test(val) || 'Introduzca una dirección de correo electrónico válida')
    },
    async onLogin () {
       if (this.register){
          this.$refs.email.validate()
          this.$refs.username.validate()
          this.$refs.password.validate()
          this.$refs.repassword.validate()
       } else {
          this.$refs.email.validate()
          this.$refs.password.validate()      
       }
      
        if (!this.register) 
        if (!this.$refs.email.hasError && (!this.$refs.password.hasError)) {
                this.loading = true;
                let { email, password } = this;
                const response = await axiosServices.onAxiosPost("login", { email, password })
                if(response.code === 200) {
                    this.$store.dispatch('onSetLogin', true)
                    this.$store.dispatch('onSetToken', response.data.token)
                    this.$store.dispatch('onSetUser', response.data.user)
                    this.$router.replace({name: "admin"})
                } else {
                    this.$q.notify({
                        type: 'negative',
                        position: 'top-right',
                        message: 'Correo o contraseña incorrectas'
                    });
                }
                this.loading = false;
            }
    },
    switchVisibility() {
      this.visibility = !this.visibility
      this.passwordFieldType = this.visibility ? 'text' : 'password'
      this.visibilityIcon =  this.visibility ? 'visibility_off' : 'visibility'
    }
  }
}
</script>
<style lang="scss">
.logoimage {
    width: 90%;
    min-width: 200px;
}
</style>