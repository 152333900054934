<template>
    <q-card flat bordered>
      <q-card-section horizontal>
        <q-card-section class="col-5 flex flex-center">
          <q-icon :name="icon" size="lg" />
        </q-card-section>
        <q-card-section class="q-pt-xs" style="font-size: 1.2rem;">
          <div class="q-mt-sm q-mb-xs" v-text="title"></div>
          <div class="text-grey" v-text="num"></div>
        </q-card-section>
        <q-card-section>
            <q-btn flat  round color="grey" icon="info_outlined">
                <q-tooltip class="bg-grey" v-text="tooltip"></q-tooltip>
            </q-btn>
        </q-card-section>
      </q-card-section>
    </q-card>
</template>
<script>
export default {
    name: 'DashCard',
    props: {
        icon: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        tooltip: {
            type: String,
            default: ''
        },
        num: {
            type: Number,
            default: 0
        }
    }
}
</script>