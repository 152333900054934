<template>
  <q-select
    class="q-mt-sm"
    :label="`${sLabel}${bRequired ? ' *' : ''}`"
    :disable="bDisabled"
    v-model="valor"
    :options="options"
    option-value="id"
    :option-label="sKey"
    :rules="noVacio"
    :loading="loadSelect"
    @filter="onFilterData"
    multiple
    options-dense
    use-chips
    use-input
    outlined
    filled=""
    dense>
  </q-select>
</template>
<script>
import axiosServices from '@/services/axiosServices'
import { isUndefined } from 'util'

export default {
  name: "FormMultiSelect",
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: ''
    },
    sLabel: {
      type: String,
      default: ''
    },
    sEndPointSelect: {
      type: String,
      default: ''
    },
		bDisabled: {
      type: Boolean
    },
    oValorField: {}
  },
  data() {
    return {
      valor: this.value,
      aItemsData: [],
      loadSelect: false,
      options: this.aItemsData,
      noVacio: [v => !!v],
      sKey: ''
    }
  },
  async mounted() {
    await this.onGetItems()
  },
  methods: {
    async onGetItems() {
      this.loadSelect = true
      const aItems = await axiosServices.onAxiosGet(this.sEndPointSelect)
      this.aItemsData = aItems.data
      if(this.aItemsData.length > 0) {
        if ("name" in this.aItemsData[0]) {
          this.sKey = 'name'
        } else {
          this.sKey = 'description'
        }
      }
      this.loadSelect = false
    },
    onFilterData(val, update) {
      if (val === '') {
        update(() => {
          this.options = this.aItemsData
        })
      }
      update(() => {
        const needle = val.toLowerCase()
        this.options = this.aItemsData.filter(v => ("name" in v) ? v.name.toLowerCase().indexOf(needle) > -1 : v.description.toLowerCase().indexOf(needle) > -1)
      })
    }
  },
  computed: {
    
  },
  watch: {
    valor (newValor) {
      if (newValor !== null) {
        this.$emit('updateValor', newValor)
      }
    },
    value (newValue) {
      this.valor = newValue
    },
    aItemsData () {
      if (`${this.sKeyField}` in this.oValorField) {
        let aTemporal = []

        if (!isUndefined(this.oValorField[`${this.sKeyField}`].length)) {
          this.oValorField[`${this.sKeyField}`].forEach(nItems => {
            aTemporal.push(this.aItemsData.find(element => element.id == (typeof nItems === 'object' ? nItems.id : nItems)));
          });
        } else {
          aTemporal.push(this.aItemsData.find(element => element.id == this.oValorField[`${this.sKeyField}`]))
        }        
        aTemporal = aTemporal.filter(oTemportal => oTemportal);
        this.valor = aTemporal
      } else {
        this.valor = Array()
      }
    },
    sEndPointSelect() { 
      this.valor = [];
      this.onGetItems();
    }
  }
}
</script>