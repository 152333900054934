<template>
    <div class="container q-mx-md q-my-lg">
        <q-card flat bordered class="q-my-lg" >
            <q-card-section>
                <div class="text-h6">Billetera</div>
            </q-card-section>
        </q-card>
        <div class="grid-container">
            <div>
                <q-card flat bordered>
                    <q-card-section horizontal>
                        <q-card-section class="col-5 flex flex-center">
                        <q-icon name="payments" size="lg" />
                        </q-card-section>
                        <q-card-section class="q-pt-xs" style="font-size: 1.2rem;">
                        <div class="q-mt-sm q-mb-xs">TOTAL</div>
                        <div class="text-grey">{{ balance.toLocaleString('es-ES') }}</div>
                        </q-card-section>
                    </q-card-section>
                </q-card>
            </div>
            <TransferCard
                :key="nKey"
                @saveOk="onRealodData"
            />
        </div>
        <q-table
            title="Transacciones"
            class="q-mt-md"
            :columns="columns"
            :rows="originalRows" 
            :loading="isLoading"
            flat
            bordered
        >
            <template v-slot:body-cell-edit="props">
                <td>
                    <q-icon name="circle" color="positive" v-if="props.row.type === 'recharge'"></q-icon>
                    <q-icon name="circle" color="negative" v-if="props.row.type === 'withdrawal'"></q-icon>
                    <q-icon name="circle" color="positive" v-if="props.row.type === 'movement' && props.row.id_for == onGetUser.id"></q-icon>
                    <q-icon name="circle" color="negative" v-if="props.row.type === 'movement' && props.row.id_from == onGetUser.id"></q-icon>
                </td>
            </template>
            <template v-slot:body-cell-type="props">
                <td v-text="capitalizeWord(getTranslation(props.row.type))" />
            </template>
        </q-table>
    </div>
</template>
<script>
import translate from '@/services/translate';
import TransferCard from '@/components/General/TransferCard.vue';
import axiosServices from '@/services/axiosServices';
import { mapGetters } from 'vuex';

export default {
    components: {
        TransferCard
    },
    data() {
        return {
            nKey: 0,
            columns: [],
            originalRows: [],
            getEndPoint: 'products',
            balance: 0,
            isLoading: false,
            data: [],
        }
    },
    methods: {
        capitalizeWord(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        getTranslation(str) {
            return translate.getTranslation(str)
        },
        onRealodData() {
            this.nKey += 1;
            this.onGetData();
        },
        async onGetData() {
            this.originalRows = [];
            this.isLoading = true;
            let endpoint = 'wallet'
            const response = await axiosServices.onAxiosGet(endpoint);
            if(!response.data.movements.length) {
                this.isLoading = false;
                return;
            }
            let aColumns = [];
            let aHeaders = Object.keys(response.data.movements[0]);
            aColumns.push({name:"edit", label: "", align: "center", headerStyle: (aHeaders.length <= 5) ? 'width: 30px': ''})
            aHeaders.forEach(sValue => {
                if (sValue.indexOf("id") !== 0) {
                    let oColumns = {
                        name: sValue,
                        required: true,
                        label: translate.getTranslation(sValue).toLocaleUpperCase(),
                        align: (sValue == 'id') ? 'center' : 'left',
                        field: row => row[`${sValue}`],
                        format: val => `${val}`,
                        sortable: true,
                        classes: (sValue == 'description') ? 'table__td--description' : "",
                        style: "word-wrap: break-word",
                        headerStyle: (sValue == 'id' && aHeaders.length <= 5) ? 'width: 20px' : '',
                        headerClasses: `table__th--${sValue}`
                    }
                    aColumns.push(oColumns);
                }
            })

            this.columns = aColumns;
            this.originalRows = response.data.movements;
            this.balance = response.data.balance;
            this.isLoading = false;
        },
    },
    mounted() {
        this.onGetData();
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
}
</script>