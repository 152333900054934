<template>
  <div class="row justify-between">
    <div class="col-sm-6 col-12">
      <q-input dense filled  model-value="*****" type="text" :label="sLabel" disable lazy-rules />
    </div>
    <div class="col-sm-5 col-12">
      <q-btn @click="onShowModal" class="full-width" color="grey">Cambiar contraseña</q-btn>
    </div>
  </div>

  <q-dialog    
      v-model="showModal"
      persistent>
      <q-card>
          <q-bar>
              <div>CAMBIAR CONTRASEÑA</div>
              <q-space/>
              <q-btn @click="onClose" icon="close" dense flat/>
          </q-bar>
          <q-card-section>
            <div class="row justify-start q-col-gutter-x-xs">
                <div class="containerModal" :class="`col-xs-12 col-sm-12 col-md-${field.nCols} col-lg-${field.nCols}`" v-for="field, nKey in aFilds" :key="nKey">
                    <component                
                    :is="field.sComponent"
                    :sLabel=field.sLabel
                    :oValorField="oValorField"
                    @updateValor="onGetValuesForm(field.sNombreCampo, $event)">
                    </component>
                    <q-separator class="q-my-md" v-if="field.bSeparator" />
                </div>
            </div>
          </q-card-section>
          <q-card-actions align="right">
              <q-btn style="min-width: 120px;" color="primary" label="Guardar" @click="onSaveForm" :disable="isLoading" :loading="isLoading"/>
          </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
import FormPassword from '@/components/Fields/FormPassword'

export default {
  name: "FormText",
  emits: ['updateValor'],
  components: {
    FormPassword
  },
  props: {
    value: {},
    sLabel: {
      type: String,
      default: ''
    },
    oValorField: {}
  },
  data() {
    return {
      valor: '',
      showModal: false,
      isLoading: false,
      oFormField: [],
      aFilds: [
        {
            sComponent: 'FormPassword',
            sNombreCampo: 'password',
            sLabel: 'Contraseña actual',
            nCols: 12,
        },
        {
            sComponent: 'FormPassword',
            sNombreCampo: 'new_password',
            sLabel: 'Contraseña nueva',
            nCols: 6,
        },
        {
            sComponent: 'FormPassword',
            sNombreCampo: 'new_password_confirmation',
            sLabel: 'Confirmar nueva contraseña',
            nCols: 6,
        },
      ]
    }
  },
  methods: {
    onShowModal() {
      this.showModal = true;
    },
    onClose() {
      this.showModal = false;
    },
    async onSaveForm() {
      this.isLoading = true;
      const response = await axiosServices.onAxiosPost('changePassword', this.oFormField);
      if(response.code === 200) {
        this.onClose();
        return this.$q.notify({
            type: 'positive',
            position: 'bottom',
            message: 'Contraseña actualizada exitosamente',
        });
    }
    },
    onGetValuesForm(index, data) {
      this.oFormField[index] = data;
    }
  },
  watch: {
    valor(newValor) {
      this.$emit('updateValor', newValor)
    },

    value(newValue) {
      this.valor = newValue
    },

    oValorField(newoValorField) {
      this.valor = newoValorField[`${this.sKeyField}`]
    },

    aItemsData(newV) {
      this.valor = newV[`${this.sKeyField}`]
    },
  }
}
</script>