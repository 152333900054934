import axios from "axios"
import store from "@/store/index.js"
import params from "@/params/params"

const oApiClient = axios.create({
  baseURL: `${params.onGetUrl(process.env.NODE_ENV, store.state.features.module)}`
})

let sProtocol  = (process.env.NODE_ENV == 'development') ? 'http:' : 'https:'

const oConfigBase = {
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${store.state.sToken}`,
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache, no-store',
    'Content-Security-Policy': `default-src 'self' ${window.location.href}; img-src 'self' ${window.location.href} data: ${sProtocol}; script-src 'self' 'unsafe-eval' ${window.location.href}; style-src 'self' 'unsafe-inline' ${window.location.href}; connect-src 'self' ${window.location.href} ${sProtocol} ws: blob: filesystem:; object-src 'none';`
  }
}

oApiClient.interceptors.request.use(config => {
  config.baseURL = `${params.onGetUrl(process.env.NODE_ENV, store.state.features.module)}`
  if (store.state.sToken !== '') {
    config.headers.Authorization = `Bearer ${store.state.sToken}`
  }
  return config
})
oApiClient.interceptors.response.use(response => {
  return response
}, (error) => {
  if(error.response.status == 401 && error.response?.data?.message == 'Unauthenticated.') {
    store.dispatch('onSetToken', '')
    store.dispatch('onSetLogin', false).then(() => {
      window.localStorage.clear()
      sessionStorage.clear()
      let sUrlDomain = window.location
      alert('Tiempo de sesión finalizada');
      window.location.href = `${sUrlDomain.origin}`
    }).catch(error => error)
  }
  return error.response
})
export default {
  //Metodo GET
  onAxiosGet(sUrl) {
    delete oConfigBase.responseType;
    return oApiClient.get(`${sUrl}`, oConfigBase).then(aGetData => aGetData.data)
  },
  //Metodo POST
  onAxiosPost(sUrl, oBody) {
    delete oConfigBase.responseType
    return oApiClient.post(`${sUrl}`, oBody, oConfigBase).then(aGetData => aGetData.data)
  },
  //Metodo PUT
  onAxiosPut(sUrl, oBody) {
    delete oConfigBase.responseType
    return oApiClient.put(`${sUrl}`, oBody, oConfigBase).then(aGetData => aGetData.data)
  },
  //Metodo DELETE
  onAxiosDelete(sUrl) {
    delete oConfigBase.responseType
    return oApiClient.delete(`${sUrl}`, oConfigBase).then(aGetData => aGetData.data)
  },
  //Metodo POST login
  onAxiosPostLogin(sUrl, oBody) {
    oConfigBase.headers.Authorization = '';
    delete oConfigBase.responseType
    return oApiClient.post(`${sUrl}`, oBody, oConfigBase).then(aGetData => aGetData.data)
  },
  //Metodo POST login
  onAxiosPostResetPasswordLink(sUrl, oBody) {
    oConfigBase.headers.Authorization = '';
    delete oConfigBase.responseType
    return oApiClient.post(`${sUrl}`, oBody, oConfigBase).then(aGetData => aGetData.data)
  },
  //Metodo Excel Blob
  onAxiosExcel(sUrl, sType) {
    oConfigBase.responseType = `${sType}`
    return oApiClient.get(`${sUrl}`, oConfigBase).then(aGetData => aGetData.data)
  },

  onAxiosPostExcel(sUrl, sType, oBody) {
    oConfigBase.responseType = `${sType}`
    return oApiClient.post(`${sUrl}`, oBody, oConfigBase).then(aGetData => aGetData.data)
  },

  //Metodo POST
  onAxiosPostWithfile(sUrl, oBody) {
    const configWithFile = JSON.parse(JSON.stringify(oConfigBase));
    configWithFile.headers['Content-Type'] = 'multipart/form-data';
    return oApiClient.post(`${sUrl}`, oBody, configWithFile).then(aGetData => aGetData.data)
  },

  onAxiosPutWithFile(sUrl, oBody) {
    const configWithFile = JSON.parse(JSON.stringify(oConfigBase));
    configWithFile.headers['Content-Type'] = 'multipart/form-data';
    return oApiClient.put(`${sUrl}`, oBody, configWithFile).then(aGetData => aGetData.data)
  },

  onAxiosGetFile(sUrl, sType) {
    const oConfig = { responseType: `${sType}` };
    return oApiClient.get(`${sUrl}`, oConfig);
  }
}