let oConfigURL = {
  onGetUrl(sEnveroiment) {
    let sUrl = ''
    if (sEnveroiment === 'development') {
      sUrl = 'http://127.0.0.1:8000/api/'
    } else {
      sUrl = `https://api.dmgcamiseta.com/api/`
    }
    return sUrl
  }
}

export default oConfigURL