<template>
    <q-dialog    
    v-model="bValor"
    persistent>
        <q-card>
            <q-bar>
                <q-space/>
                <q-btn @click="onClose" icon="close" dense flat/>
            </q-bar>
            <q-card-section>
                <p v-text="description"/>
                <div class="row justify-start q-col-gutter-x-xs">
                    <div class="containerModal" :class="`col-xs-12 col-sm-${field.nCols} col-md-${field.nCols} col-lg-${field.nCols}`" v-for="field, nKey in dinamicFields" :key="nKey">
                        <component                
                        :is="field.sComponent"
                        :sKeyField="field.sNombreCampo"
                        :sLabel=field.sLabel
                        :bRequired=field.bRequired
                        :bDisabled="field.bDisabled"
                        :sEndPointSelect="field.sEndpointSelect"
                        :oValorField="oValorField"
                        @updateValor="onGetValuesForm(field.sNombreCampo, $event)">
                        </component>
                        <q-separator class="q-my-md" v-if="field.bSeparator" />
                    </div>
                </div>
            </q-card-section>
            <q-card-actions align="between">
                <q-btn style="min-width: 120px;" label="Cancelar" @click="onClose" class="bg-negative" text-color="white"/>
                <q-btn style="min-width: 120px;" label="Confirmar" @click="onSaveForm" :disable="isDisable" :loading="bButtonDisable"  class="bg-primary" text-color="white"/>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
<script>
import FormText from '@/components/Fields/FormText'
import FormSelect from '@/components/Fields/FormSelect'
import axiosServices from '@/services/axiosServices'

export default {
    name: 'ProductCard',
    components: {
        FormText,
        FormSelect
    },
    props: {
        value: {},
        product: {}
    },
    data() {
        return {
            bValor: this.value,
            description: 'Valida tus datos personales para el envío del producto.',
            oValorField: {},
            oFormField: {},
            fields: [
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'full_name',
                    sLabel: 'Nombres',
                    nCols: 12,
                    bRequired: true,
                    sEndpointSelect: ''
                },
                {
                    sComponent: 'FormSelect',
                    sNombreCampo: 'country_code',
                    sLabel: 'País',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: 'countries',
                },
                {
                    sComponent: 'FormSelect',
                    sNombreCampo: 'department',
                    sLabel: 'Departamento',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: 'departments',
                    sDependsOn: 'country_code',
                },
                {
                    sComponent: 'FormSelect',
                    sNombreCampo: 'id_city',
                    sLabel: 'Ciudad',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: 'cities',
                    sDependsOn: 'department',
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'neighborhood',
                    sLabel: 'Barrio',
                    bRequired: true,
                    nCols: 5,
                    sEndpointSelect: ''
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'address',
                    sLabel: 'Dirección',
                    bRequired: true,
                    nCols: 7,
                    sEndpointSelect: ''
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'phone',
                    sLabel: 'Teléfono',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: ''
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'additional_references',
                    sLabel: 'Referencias adicionales de la dirección',
                    nCols: 12,
                    sEndpointSelect: ''
                },
            ],
            dinamicFields: []
        }
    },
    methods: {
        onClose() {
            this.$emit('onClose')
        },
        async onGetData() {
            this.pageLoading = true;
            const response = await axiosServices.onAxiosGet('residence_adresses');
            if(response.code === 200) {
                this.oValorField = response.data;
            }
        },
        async onSaveForm() {
            const areEmptyFields = await this.onCheckRequiredFields();
            if(areEmptyFields) {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: 'Por favor completa todos los campos requeridos (*)',
                });
            }
            const response = await axiosServices.onAxiosPost('residence_adresses', this.oFormField);
            if(response.code === 200) {
                this.onClose();
                const result = await this.$swal.fire({
                    title: '¿Estás seguro?',
                    text: `Estás seguro que deseas realizar una compra de ${this.product.name} por un valor de $${this.product.price.toLocaleString('es-ES')}`,
                    showCancelButton: true,
                    confirmButtonText: '¡Si, Confirmar!',
                    confirmButtonColor: "#26A69A",
                    cancelButtonText: `Cancelar`,
                });
                if (result.isConfirmed) {
                    let oBody = { id_residence_adresses: response.data.id, id_product: this.product.id };
                    const sale = await axiosServices.onAxiosPost('sales', oBody);
                    if(sale.code === 200) {
                        this.$store.dispatch('onSetUser', sale.data.user)
                        return this.$q.notify({ type: 'positive', position: 'bottom', message: 'Compra realizada exitosamente', });
                    } else {
                        return this.$q.notify({ type: 'negative', position: 'top-right', message: sale.message })
                    }
                }

            } else {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: Object.values(response.errors)[0]
                })
            }
        },
        onGetValuesForm(index, data) {
            // If data size is set this is file
            if(data && data.size) {
                this.oFormField[index] = data;
            } else {
                this.oFormField[index] = (Array.isArray(data)) ? data : (typeof data == 'object') ? data.id : data
            }

            if(this.dinamicFields.find(oComponent => oComponent.sDependsOn === index)) {
                const nIndex = this.dinamicFields.findIndex(oComponent => oComponent.sDependsOn === index);
                this.dinamicFields[nIndex].sEndpointSelect = `${this.fields[nIndex].sEndpointSelect}/${this.oFormField[index]}`;
            }
        },
        async onCheckRequiredFields() {
            let nEmptyFields = 0;
            this.fields.forEach(field => {
                if(field.bRequired && !this.oFormField[field.sNombreCampo]) {
                    nEmptyFields += 1;
                }
            })
            return nEmptyFields >= 1;
        },
    },
    mounted() {
        this.onGetData();
        this.dinamicFields = this.fields.map(oComponent => {
            return {
                ...oComponent, 
                sEndpointSelect: typeof oComponent.sEndpointSelect == 'object' ? oComponent.sEndpointSelect : (oComponent.sEndpointSelect ? `${oComponent.sEndpointSelect}${oComponent.sDependsOn ? `/${this.oValorField[oComponent.sDependsOn]}` : ''}` : oComponent.sEndpointSelect )
            }
        });
    },
}
</script>