<template>
    <q-card flat bordered class="q-mb-sm">
      <q-card-section>
        <div class="text-h6">Patricinadores</div>
      </q-card-section>
      <q-separator />
      <q-card-section v-if="pageLoading" class="text-center">
        <q-spinner
          color="primary"
          size="3em"
        />
      </q-card-section>
      <q-card-section v-else>
        <div class="row q-mb-md">
          <div class="col-md-4 col-12"><span style="color: gray;">Patrocinador directo</span></div>
          <div class="col-2">{{ oFormField.id_sponsor_user }}</div>
          <div class="col-6">{{ oFormField.sponsor_user }}</div>
        </div>

        <div class="row q-mb-md">
          <div class="col-md-4 col-12"><span style="color: gray;">Patrocinador adoptivo</span></div>
          <div class="col-2">{{ oFormField.id_adoptive_sponsor }}</div>
          <div class="col-6">{{ oFormField.adoptive_sponsor }}</div>
        </div>
      </q-card-section>
      <q-separator />
    </q-card>

    <q-stepper
      v-model="step"
      header-nav
      ref="stepper"
      color="primary"
      animated
      flat
      bordered
    >
      <q-step
        v-for="(page, key) in validatedPages" :key="key"
        :name="key"
        :title="page.title"
        :icon="page.icon"
      >
      <ProfileForm :fields="page.fields" :oValorField="oValorField" @onGetValor="onGetValor"/>
      </q-step>
      <template v-slot:navigation v-if="!isDisabled">
        <div class="row justify-end q-pr-md q-pb-md">
          <q-btn @click="onSaveData" :loading="isLoading" class="full-width" color="primary" style="max-width: 200px;">Guardar</q-btn>
        </div>
      </template>
    </q-stepper>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import ProfileForm from '@/components/Form/ProfileForm.vue'
export default {
    name: 'ProfileGrid',
    props: {
        pages: {},
        isDisabled: {
          type: Boolean,
          default: false
        },
        getEndPoint: {
            type: String,
            default: ''
        },
        putEndPoint: {
            type: String,
            default: ''
        },
    },
    components: {
        ProfileForm,
    },
    data() {
        return {
            step : 0,
            pageLoading: false,
            isLoading: false,
            oFormField: {},
            oValorField: {},
            validatedPages: [],
        }
    },
    methods: {
        async onGetData() {
            this.pageLoading = true;
            const response = await axiosServices.onAxiosGet(this.getEndPoint);
            if(response.code === 200) {
                this.oValorField = response.data;
                this.oFormField = response.data;
            }
            this.pageLoading = false;
            },
            onGetValor({index, data}) {
            this.oFormField[index] = data;
        },
        async onSaveData() {
            this.isLoading = true;
            const response = await axiosServices.onAxiosPut(this.putEndPoint, this.oFormField);
            if(response.code === 200) {
                this.$q.notify({ type: 'positive', position: 'top-right', message: 'Información actualizada exitosamente'});
            }
            this.isLoading = false;
        },
        onCheckDisable() {
          this.validatedPages = this.pages.map(page => {
            const fields = page.fields.map(field => {
              return {...field, bDisabled: field.bDisabled ?? this.isDisabled}
            });
            return {...page, fields}
          });
        }
    },
    mounted() {
        this.onGetData();
        this.onCheckDisable();
    }
}
</script>