import store from '@/store/index.js'
import { createRouter, createWebHistory } from 'vue-router'

import NotFound from '../views/PageNotFound.vue'
import LoginPage from '../views/LoginPage.vue'
import DashboardPage from '../views/DashboardPage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import UsersPage from '../views/UsersPage'
import AddMemberPage from '../views/AddMemberPage.vue'
import UserVision from '../views/UsersPage/show.vue'
import UserEdit from '../views/UsersPage/edit.vue';
import Permissions from '../views/PermissionsPage.vue';
import Recharge from '../views/Wallet/RechargePage.vue';
import Withdrawal from '../views/Wallet/WithdrawalPage.vue';
import BuyCoffee from '../views/Wallet/BuyCoffeePage.vue';
import Wallet from '../views/Wallet/WalletPage.vue';
import ProfitPage from '../views/Wallet/ProfitPage.vue';
import ShipmentProducts from '../views/Wallet/ShipmentProducts';
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/agregar/:id',
    name: 'agregar',
    component: AddMemberPage
  },
  {
    path: '/admim',
    name: 'admin',
    component: DashboardPage
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: ProfilePage
  },
  {
    path: '/listar-usuarios',
    name: 'listar-usuarios',
    component: UsersPage
  },
  {
    path: '/vision-usuario',
    name: 'vision-usuario',
    component: UserVision
  },
  {
    path: '/edicion-usuario/:id',
    name: 'edicion-usuario',
    component: UserEdit
  },
  {
    path: '/permisos',
    name: 'permisos',
    component: Permissions
  },
  {
    path: '/cargar-cartera',
    name: 'cargar-cartera',
    component: Recharge
  },
  {
    path: '/retiro-cartera',
    name: 'retiro-cartera',
    component: Withdrawal
  },
  {
    path: '/comprar-productos',
    name: 'comprar-productos',
    component: BuyCoffee
  },
  {
    path: '/billetera',
    name: 'billetera',
    component: Wallet
  },
  {
    path: '/ganancias',
    name: 'ganancias',
    component: ProfitPage
  },
  {
    path: '/envios-productos',
    name: 'envios-productos',
    component: ShipmentProducts
  },
  { path: '/:pathMatch(.*)', name: 'not-found', component: NotFound },
]
const router = createRouter({
  history: createWebHistory(`${process.env.BASE_URL}`),
  routes
})


// interceptor para validar que se este logueado
router.beforeEach ((to, from, next) => {
  // console.log(to)
  // console.log(from)
  if(to.query.t != undefined && to.query.ip != undefined){
    // console.log("query","ingreso validacion exitosa", to.query)
    let sToken = to.query.t
    let sIP = to.query.ip
    store.dispatch('onSetLogin', true)
    store.dispatch('onSetToken', sToken)
    sessionStorage.ip = sIP
  }
  
  switch (to.name) {
    case "login":
    case "agregar":
    case "twoauth": {
      next();
      break;
    }
    default: {
      if (!store.state.bLogin) {
        next('/');
      } else {
        next();
      }
      break;
    }
  }
})


export default router
