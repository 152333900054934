<template>
    <div :class="classes">
        <q-btn outline @click="onShowForm" class="full-width" color="primary" label="Nuevo Rol" />
        <div class="text-h6 q-mt-md">Roles</div>
        <div class="row q-mt-md" style="flex-direction: column; gap: .5rem;">
            <div v-if="isLoading">
                CARGANDO.....
            </div>
            <div v-else @click="onShowPermission(oData.id)" class="row btn-role justify-between q-px-md" v-for="oData, key in aData" :key="key">
                <span v-text="oData.name" />
                <q-btn @click="onDelete(oData.id)" flat color="negative" size="sm" icon="delete"></q-btn>
            </div>
        </div>
    </div>
    <GenericForm
        :key="nKey"
        :fields="fields"
        :o-valor-field="{}"
        title="REGISTRAR ROL"
        endpoint="roles"
        v-model="showForm"
        @onSave="onSave"
        @onClose="closeForm"
    />
    <RolesForm 
        :key="nKey"
        :id="role_id"
        endpoint="permission"
        v-model="showPermission"
        @onClose="closeForm"
    />
</template>
<script>
import axiosServices from '@/services/axiosServices';
import RolesForm from '@/components/Form/RolesForm.vue';
import GenericForm from '@/components/Form/GenericForm.vue';
export default {
    name: 'RolesGrid',
    props: {
        classes: {
            type: String,
            default: ''
        }
    },
    components: {
        RolesForm,
        GenericForm
    },
    data() {
        return {
            nKey: 0,
            isLoading: false,
            role_id: null,
            showForm: false,
            showPermission: false,
            aData: [],
            fields: [
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'name',
                    sLabel: 'Nombre',
                    nCols: 6,
                    sEndpointSelect: '',
                    bRequired: true
                },
            ]
        }
    },
    methods: {
        onSave() {
            this.onGetData();
        },
        onShowForm() {
            this.nKey += 1;
            this.showForm = true;
        },  
        onShowPermission(id) {
            this.nKey += 1;
            this.role_id = id;
            this.showPermission = true;
        },
        closeForm() {
            this.showForm = false;
            this.showPermission = false;
        },
        async onGetData() {
            this.isLoading = true;
            const response = await axiosServices.onAxiosGet('roles');
            this.aData = response.data;
            this.isLoading = false;
        },
        async onDelete(id) {
            this.$swal.fire({
                title: '¿Estás seguro?',
                text: 'Una vez confirmes no podrás revertir esta acción',
                showCancelButton: true,
                confirmButtonText: '¡Si, Elimínalo!',
                confirmButtonColor: "#26A69A",
                cancelButtonText: `Cancelar`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await axiosServices.onAxiosDelete(`roles/${id}`);
                    if(response.code === 200) this.onGetData();
                } 
            }).catch(err => console.log(err))
            
        }
    },
    mounted() {
       this.onGetData();
    }
}
</script>
<style lang="scss" scoped>
@import '../../styles/RolesGrid.scss';
</style>