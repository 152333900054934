<template>
    <div class="container q-mx-md q-my-lg">
      <q-card class="q-my-lg">
            <q-card-section>
                <div class="text-h6">Retiro Cartera</div>
            </q-card-section>
          <q-separator />
          <q-card-section>
              <p>Busca un usuario</p>
              <div class="row justify-between">
                <div class="col-sm-8 col-12">
                  <q-input dense filled  v-model="document" type="number" label="Buscar documento" lazy-rules />
                </div>
                <div class="col-sm-3 col-12">
                  <q-btn class="full-width" color="secondary" label="Buscar" icon="search" :loading="isLoadingSearch" @click="searchUser"/>
                </div>
              </div>
          </q-card-section>
      </q-card>
      <q-card v-if="user_exists && !isLoadingSearch">
        <q-card-section>
            <div class="row">
                <div class="col-sm-4 q-px-sm">
                    <q-input dense filled v-model="user.code" type="text" :disable="true" label="Código" lazy-rules />
                </div>
                <div class="col-sm-8 q-px-sm">
                    <q-input dense filled v-model="user.name" type="text" :disable="true" label="Nombre" lazy-rules />
                </div>
                <div class="col-12 q-mt-sm q-px-sm">
                    <q-input dense filled v-model="user.money" type="text" :disable="true" label="Saldo actual" lazy-rules prefix="$" />
                </div>
            </div>
            <div class="row">
                <div class="q-mt-md q-px-sm col-sm-8">
                    <q-input dense filled type="number" v-model="money" label="Monto de retiro" @change="validarInput" lazy-rules prefix="$" />
                </div>
                <div class="q-mt-md col-sm-4">
                  <q-btn class="full-width" color="negative" label="confirmar retiro" :disable="money <= 0" @click="rechargeWallet"/>
                </div>
            </div>
        </q-card-section>
      </q-card>
    </div>
  </template>
  <script>
  import axiosServices from '@/services/axiosServices';
  
  export default {
    name: "UsersPage",
    components: {
      
    },
    data() {
      return {
        nKey: 0,
        title: 'Cargar Cartera',
        getEndPoint: '',
        document: '',
        user: null,
        money: '',
        user_exists: false,
        isLoadingSearch: false,
      }
    },
    methods: {
        validarInput(val) {
            this.money = parseFloat(val) || null;
        },
        async rechargeWallet() {
            const result = await this.$swal.fire({
                title: '¿Estás seguro?',
                text: `Estás seguro que deseas realizar un retiro a esta cuenta por valor de $${this.money.toLocaleString('es-ES')}`,
                showCancelButton: true,
                confirmButtonText: '¡Si, Confirmar!',
                confirmButtonColor: "#26A69A",
                cancelButtonText: `Cancelar`,
            })
            if (result.isConfirmed) {
                const oBody = { amount: this.money }
                const response = await axiosServices.onAxiosPost(`wallet/withdrawal/${this.user?.code}`, oBody);
                if(response.code == 200) {
                    this.searchUser();
                    return this.$q.notify({
                        type: 'positive',
                        position: 'bottom',
                        message: `Se retiraron $${this.money.toLocaleString('es-ES')} exitosamente a ${this.user?.name}`,
                    });
                }
            } 
        },
        async searchUser() {
            this.isLoadingSearch = true;
            const response = await axiosServices.onAxiosGet(`wallet/search/${this.document}`);
            switch (response.code) {
                case 200:
                    this.user = response.data;
                    this.user_exists = true;
                    this.money = '';
                    this.user.money = this.user.money.toLocaleString('es-ES')
                    break;
                case 404:
                    this.$q.notify({
                        type: 'negative',
                        position: 'top-right',
                        message: 'Usuario no encontrado'
                    });
                    break;
                default:
                    break;
            }
            this.isLoadingSearch = false;
      },
    },
    mounted() {
      let { code } = this.$route.query;
      if(code) {
        this.code = code;
        this.searchUser();
      }
    }
  }
  </script>