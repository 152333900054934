<template>
    <div class="container q-mx-md q-my-lg">
        <q-card bodered flat class="q-my-lg" v-if="isRegistered">
            <q-card-section>
                <div class="text-h6 justify-center  row items-center " style="gap: 1rem;">
                    ¡Bienvenido(a)!
                </div>
            </q-card-section>
            <q-card-section class="text-center">
                <p>Tu membresía ha sido creada satisfactoriamente.</p>
                <p>Tu código de afiliación es: {{ savedData.id }}</p>
                <p class="text-h6">Inicia sesión con los siguientes datos</p>
                <p>Correo: <strong>{{ savedData.email }}</strong></p>
                <p>Contraseña: <strong>{{ savedData.password }}</strong></p>
                <p>Equipo, <b>CAMISETAS LIBERTAD DAVID MURCIA GUZMÁN</b></p>
                <div class="row justify-center items-center" style="gap: 1rem;">
                    <q-btn @click="onResetForm" color="secondary" label="Nuevo Registro" />
                    o
                    <q-btn @click="onGoToLogin" color="orange" label="Iniciar Sesión"/>
                </div>
            </q-card-section>
        </q-card>
        <template v-else>
            <q-card bordered flat class="q-my-md">
                <q-card-section>
                    <div class="text-h6 justify-center  row items-center " style="gap: 1rem;">
                        <q-icon name="person_add_alt" size="1.8rem"/> NUEVO REGISTRO
                    </div>
                </q-card-section>
    
                <q-separator inset />
                <q-card-section v-if="isLoading" class="text-center">
                    <q-spinner
                        color="grey"
                        size="3em"
                    />
                </q-card-section>
                <template v-if="spuser && loaded">
                    <q-card-section>
                        <div class="text-h6">¡Bienvenido! Tu patrocinador es: <span class="text-negative">{{ spuser?.name }}</span></div>
                    </q-card-section>
                    <q-card-section>
                        <FormAdoptiveSponsor @updateValor="onSetAdoptiveCode"/>
                    </q-card-section>
                </template>
                <q-card-section v-if="!spuser && loaded">
                    <div class="text-h6 text-negative text-center">El código de patrocinador no existe</div>
                </q-card-section>
            </q-card>
            <DefaultForm 
                v-if="spuser && loaded"
                :key="nKey"
                :value="value"
                endpoint="users"
                :fields="fields"
                @onSaved="onSaved"
            />
        </template>

    </div>
</template>
<script>
import DefaultForm from '@/components/Form/DefaultForm.vue';
import axiosServices from '@/services/axiosServices';
import FormAdoptiveSponsor from '@/components/Fields/FormAdoptiveSponsor.vue';
export default {
    name: 'AddMemberPage',
    components: {
        DefaultForm,
        FormAdoptiveSponsor
    },
    data(){
        return {
            isLoading: false,
            isRegistered: false,
            loaded: false,
            savedData: {},
            value: {},
            spuser: {},
            fields: [
                {
                    sComponent: 'FormNumber',
                    sNombreCampo: 'card_id',
                    sLabel: 'Documento',
                    nCols: 12,
                    bRequired: true,
                    sEndpointSelect: ''
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'name',
                    sLabel: 'Nombres',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: ''
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'last_name',
                    sLabel: 'Apellidos',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: ''
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'primary_phone',
                    sLabel: 'Teléfono',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: '',
                },
                {
                    sComponent: 'FormSelect',
                    sNombreCampo: 'country_code',
                    sLabel: 'País',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: 'countries',
                },
                {
                    sComponent: 'FormSelect',
                    sNombreCampo: 'department',
                    sLabel: 'Departamento',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: 'departments',
                    sDependsOn: 'country_code',
                },
                {
                    sComponent: 'FormSelect',
                    sNombreCampo: 'id_city',
                    sLabel: 'Ciudad',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: 'cities',
                    sDependsOn: 'department',
                },
                {
                    sComponent: 'FormText',
                    sNombreCampo: 'adress',
                    sLabel: 'Dirección barrio o localidad',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: '',
                    bSeparator: true
                },

                {
                    sComponent: 'FormEmail',
                    sNombreCampo: 'email',
                    sLabel: 'Correo',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: ''
                },

                
                {
                    sComponent: 'FormPassword',
                    sNombreCampo: 'password',
                    sLabel: 'Contraseña',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: ''
                },

                {
                    sComponent: 'FormPassword',
                    sNombreCampo: 'password_confirmation',
                    sLabel: 'Confirmar contraseña',
                    bRequired: true,
                    nCols: 6,
                    sEndpointSelect: '',
                    bSeparator: true
                },
                {
                    sComponent: 'FormTerms',
                    sNombreCampo: 'terms',
                    sLabel: 'He leído y estoy de acuerdo con los términos y condiciones',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: '',
                },
                {
                    sComponent: 'FormContract',
                    sNombreCampo: 'contract',
                    sLabel: 'He leído y estoy de acuerdo con el contrato de afiliación',
                    bRequired: true,
                    nCols: 12,
                    sEndpointSelect: '',
                },
            ],
        }
    },
    
    methods: {
        async onGetData() {
            this.isLoading = true;
            const response = await axiosServices.onAxiosGet(`personByCode/${this.$route.params.id}`);
            this.spuser = response.data;
            this.value = { id_sponsor_user: this.spuser.code }
            this.isLoading = false;
            this.loaded = true;
        },
        onSetAdoptiveCode(code) {
            this.value = {id_sponsor_user: this.spuser.code, id_adoptive_sponsor: code}
        },
        onSaved(oFormData) {
            this.isRegistered = true
            this.savedData = oFormData;
        },
        onResetForm() {
            this.nKey += 1;
            this.isRegistered = false;
            this.value = {};
        },
        onGoToLogin() {
            this.$router.replace({name: "login"})
        },
    },
    mounted() {
        this.onGetData();
    }
}
</script>