<template>
    <q-dialog    
    v-model="bValor"
    persistent>
        <q-card>
            <q-bar>
                <div>Permisos</div>
                <q-space/>
                <q-btn @click="onClose" icon="close" dense flat/>
            </q-bar>
            <q-card-section>
                <div class="row">
                    <div class="col-sm-6 col-12" v-for="oData, key in aData" :key="key">
                        <q-checkbox v-model="oData.value" :label="oData.name" color="cyan" size="sm" />
                    </div>
                </div>
            </q-card-section>
            <q-card-actions align="between">
                <q-btn style="min-width: 120px;" label="Cancelar" @click="onClose" color="negative"/>
                <q-btn style="min-width: 120px;" label="Guardar"  @click="onSaveForm" color="primary"  :disable="isLoading" :loading="isLoading" />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'

export default {
    name: 'RolesForm',
    components: {},
    props: {
        id: {},
        value: {},
        fields: {},
        endpoint: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            bValor: this.value,
            aData: [],
        }
    },
    methods: {
        onClose() {
            this.$emit("onClose")
        },
        async onGetData() {
            const response = await axiosServices.onAxiosGet(this.endpoint);
            this.aData = response.data.map(({id, name}) => {
                return { id, name, value: false }
            });
        },
        async onGetRolePermissions() {
            if(!this.id) return;
            const response = await axiosServices.onAxiosGet(`roleHasPermissions/${this.id}`);
            this.aData.map(oData => {
                return oData.value = response.data.find(permission => permission.id == oData.id) ? true : false;
            })
        },
        async onSaveForm() {
            this.isLoading = true;
            const aPermissions = this.aData.filter(oData => oData.value);
            const permission_ids = aPermissions.map(oPermission => {
                return oPermission.id
            });
            let oFormData = {role_id: this.id, permission_ids }
            const response = await axiosServices.onAxiosPost(this.endpoint, oFormData);
            if(response.code === 200) {
                this.$emit('onClose')
            }
            this.isLoading = false;
        },
    },
    mounted() {
        this.onGetData();
        this.onGetRolePermissions();
    },
    watch: {}
}
</script>