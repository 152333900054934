<template>
    <div class="q-mb-sm">
        <q-checkbox v-model="valor" :label="sLabel" />
    </div>
    <!-- <q-dialog    
        v-model="showForm"
        persistent
    >
        <q-card>
            <q-bar>
                <div>Autorización para tratamiento de datos personales</div>
            </q-bar>
            <q-card-section>
                <p>De conformidad con lo definido por la Ley 1581 de 2012, el Decreto Reglamentario 1377 de 2013, la Circular Externa 002 de 2015 expedida por la Superintendencia de Industria y Comercio, la política interna de manejo de la información implementada por la sociedad COMERCIALIZADORA 3B S.A.S, sociedad legalmente constituida por documento privado del 13 de octubre de 2020, inscrito en el registro mercantil de la Cámara de Comercio de Putumayo el día 14 de octubre de 2020 con el número 9548 del libro IX, con domicilio en Puerto Guzmán, Putumayo, con NIT. 901422267-9, y las demás normas concordantes, a través de las cuales se establecen disposiciones generales en materia de hábeas data y se regula el tratamiento de la información que contenga datos personales, me permito declarar de manera expresa que:</p>
                <p>PRIMERO. Autorizo de manera libre, voluntaria, previa, explícita, informada e inequívoca a la sociedad COMERCIALIZADORA 3B S.A.S, para que en los términos legalmente establecidos realice la recolección, almacenamiento, uso, circulación, supresión y en general, el tratamiento de los datos personales que he procedido a entregar o que entregaré, en virtud de las relaciones legales, contractuales, comerciales y/o de cualquier otra índole que surja, en desarrollo y ejecución de los fines descritos en el presente documento.</p>
                <p>SEGUNDO. Dicha autorización para adelantar el tratamiento de mis datos personales, se extiende durante la totalidad del tiempo en el que pueda llegar consolidarse un vínculo o este persista por cualquier circunstancia con la sociedad COMERCIALIZADORA 3B S.A.S y con posterioridad al fenecimiento del mismo, siempre que tal tratamiento se encuentre relacionado con las finalidades para las cuales los datos personales, fueron inicialmente suministrados.</p>
                <p>TERCERO. En ese sentido, declaro conocer que los datos personales objeto de tratamiento, serán utilizados específicamente para las finalidades derivadas como VENDEDOR INDEPENDIENTE.</p>
                <p>CUARTO. Der igual forma, declaro que me han sido informados y conozco los derechos que el ordenamiento legal y la jurisprudencia, conceden al titular de los datos personales y que incluyen entre otras prerrogativas las que a continuación se relacionan: 1.) Conocer, actualizar y rectificar datos personales frente a los responsables o encargados del tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado, 2.) solicitar prueba de la autorización otorgada al responsable del tratamiento salvo cuando expresamente se exceptúe como requisito para el tratamiento, 3.) ser informado por el responsable del tratamiento o el encargado del tratamiento, previa solicitud, respecto del uso que le ha dado a mis datos personales, 4.) presentar ante la Superintendencia de Industria y Comercio quejas por infracciones al régimen de protección de datos personales, 5.) revocar la autorización y/o solicitar la supresión del dato personal cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales y 6.) acceder en forma gratuita a mis datos personales que hayan sido objeto de Tratamiento.</p>
                <p>QUINTO. La política de manejo de datos personales adoptada por la sociedad  COMERCIALIZADORA 3B S.A.S, se encuentran en la página web.</p>
            </q-card-section>
            <q-card-actions>
              <q-btn class="full-width" color="primary" label="Aceptar" @click="onClose"/>
            </q-card-actions>
        </q-card>
    </q-dialog> -->
  </template>
  
  <script>
  
  export default {
    name: "FormText",
    emits: ['updateValor'],
    props: {
      value: {
        default: false
      },
      sKeyField: {
        type: String,
        default: ''
      },
      sLabel: {
        type: String,
        default: ''
      },
      bRequired: {
        type: Boolean,
        default: false
      },
      bDisabled: {
        type: Boolean,
        default: false
      },
      oValorField: {}
    },
    data() {
      return {
        valor: false,
        showForm: false,
        aItemsData: {}
      }
    },
    methods: {
        onClose() {
            this.showForm = false;
        }
    },
    mounted() {
      this.aItemsData = this.oValorField;
    },
    watch: {
      valor(newValor) {
        if(this.valor) this.showForm = true;
        this.$emit('updateValor', newValor)
      },
  
      value(newValue) {
        this.valor = newValue
      },
  
      oValorField(newoValorField) {
        this.valor = newoValorField[`${this.sKeyField}`]
      },
  
      aItemsData(newV) {
        this.valor = newV[`${this.sKeyField}`] ?? false
      },
    }
  }
  </script>