<template>
  <div class="container q-mx-md q-my-lg">
    <q-card class="q-my-lg">
        <q-card-section>
            <p>Busca un usuario para ver su perfíl</p>
            <div class="row justify-between">
              <div class="col-sm-8 col-12">
                <q-input dense filled  v-model="code" type="number" label="Buscar código" lazy-rules />
              </div>
              <div class="col-sm-3 col-12">
                <q-btn class="full-width" color="secondary" label="Buscar" icon="search" :loading="isLoadingSearch" @click="searchUser"/>
              </div>
            </div>
        </q-card-section>
    </q-card>
    <ProfileGrid 
      v-if="userExists"
      :key="nKey"
      :pages="pages"
      :getEndPoint="getEndPoint"
      :putEndPoint="putEndPoint"
      :isDisabled="true"
    />
  </div>
</template>
<script>
import axiosServices from '@/services/axiosServices';
import ProfileGrid from '@/components/Grid/ProfileGrid.vue'


export default {
  name: "UsersPage",
  components: {
    ProfileGrid
  },
  data() {
    return {
      nKey: 0,
      title: 'Lista de Usuarios',
      getEndPoint: '',
      code: '',
      isLoadingSearch: false,
      userExists: null,
      pages: [
        {
          title: 'Información Personal',
          icon: 'person',
          fields: [
            {
                sComponent: 'FormNumber',
                sNombreCampo: 'card_id',
                sLabel: 'Documento',
                nCols: 12,
                sEndpointSelect: ''
            },
            {
                sComponent: 'FormText',
                sNombreCampo: 'name',
                sLabel: 'Nombres',
                nCols: 6,
                sEndpointSelect: ''
            },
            {
                sComponent: 'FormText',
                sNombreCampo: 'last_name',
                sLabel: 'Apellidos',
                nCols: 6,
                sEndpointSelect: ''
            },
          ]
        },
        {
          title: 'Información de contacto',
          icon: 'phone',
          fields: [
            {
                sComponent: 'FormText',
                sNombreCampo: 'primary_phone',
                sLabel: 'Teléfono',
                nCols: 12,
                sEndpointSelect: ''
            },
            {
                sComponent: 'FormSelect',
                sNombreCampo: 'country_code',
                sLabel: 'País',
                nCols: 6,
                sEndpointSelect: 'countries',
            },
            {
                sComponent: 'FormSelect',
                sNombreCampo: 'department',
                sLabel: 'Departamento',
                nCols: 6,
                sEndpointSelect: 'departments',
                sDependsOn: 'country_code',
            },
            {
                sComponent: 'FormSelect',
                sNombreCampo: 'id_city',
                sLabel: 'Ciudad',
                nCols: 12,
                sEndpointSelect: 'cities',
                sDependsOn: 'department',
            },
          ]
        },
        {
          title: 'Información de acceso',
          icon: 'lock_open',
          fields: [
            {
                sComponent: 'FormEmail',
                sNombreCampo: 'email',
                sLabel: 'Correo',
                nCols: 12,
                sEndpointSelect: ''
            },
          ]
        }
      ]
    }
  },
  methods: {
    async searchUser() {
      this.isLoadingSearch = true;
      const response = await axiosServices.onAxiosGet(`personByCode/${this.code}`);
      if(response.data) {
        this.getEndPoint = `users/${response.data.code}`
        this.userExists = true;
        this.nKey += 1;
      } else {
        this.userExists = false;
        this.$q.notify({
            type: 'negative',
            position: 'top-right',
            message: 'Este código no está asignado a ningún usuario'
        });
      }
      this.isLoadingSearch = false;
    },
  },
  mounted() {
    let { code } = this.$route.query;
    if(code) {
      this.code = code;
      this.searchUser();
    }
  }
}
</script>