<template>
    <q-card bordered flat>
        <q-card-section>
            <div class="row justify-start q-col-gutter-x-xs">
                <div class="containerModal" :class="`col-xs-12 col-sm-12 col-md-${field.nCols} col-lg-${field.nCols}`" v-for="field, nKey in dinamicFields" :key="nKey">
                    <component                
                    :is="field.sComponent"
                    :sKeyField="field.sNombreCampo"
                    :sLabel=field.sLabel
                    :bRequired=field.bRequired
                    :bDisabled="field.bDisabled"
                    :sEndPointSelect="field.sEndpointSelect"
                    :oValorField="oValorField"
                    @updateValor="onGetValuesForm(field.sNombreCampo, $event)">
                    </component>
                    <q-separator class="q-my-md" v-if="field.bSeparator" />
                </div>
            </div>
        </q-card-section>
        <q-card-actions align="right">
            <q-btn style="min-width: 120px;" label="Guardar" @click="onSaveForm" :disable="isLoading" :loading="isLoading"  class="bg-primary" text-color="white"/>
        </q-card-actions>
    </q-card>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'

import FormText from '@/components/Fields/FormText'
import FormNumber from '@/components/Fields/FormNumber'
import FormEmail from '@/components/Fields/FormEmail'
import FormPassword from '@/components/Fields/FormPassword'
import FormSelect from '@/components/Fields/FormSelect'
import FormSearchSelect from '@/components/Fields/FormSearchSelect'
import FormContract from '@/components/Fields/FormContract'
import FormTerms from '@/components/Fields/FormTerms'
export default {
    name: 'DefaultForm',
    components: {
        FormText,
        FormNumber,
        FormEmail,
        FormPassword,
        FormSelect,
        FormSearchSelect,
        FormContract,
        FormTerms
    },
    props: {
        value: {},
        fields: {},
        endpoint: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            dinamicFields: [],
            oValorField: {},
            oFormField: {}
        }
    },
    methods: {
        onClose() {
            this.$emit("onClose", false)
        },
        onGetValuesForm(index, data) {
            // If data size is set this is file
            if(data && data.size) {
                this.oFormField[index] = data;
            } else {
                this.oFormField[index] = (Array.isArray(data)) ? data : (typeof data == 'object') ? data.id : data
            }

            if(this.dinamicFields.find(oComponent => oComponent.sDependsOn === index)) {
                const nIndex = this.dinamicFields.findIndex(oComponent => oComponent.sDependsOn === index);
                this.dinamicFields[nIndex].sEndpointSelect = `${this.fields[nIndex].sEndpointSelect}/${this.oFormField[index]}`;
            }
        },
        async onCheckRequiredFields() {
            let nEmptyFields = 0;
            this.fields.forEach(field => {
                if(field.bRequired && !this.oFormField[field.sNombreCampo]) {
                    nEmptyFields += 1;
                }
            })
            return nEmptyFields >= 1;
        },
        async onSaveForm() {
            const areEmptyFields = await this.onCheckRequiredFields();
            if(areEmptyFields) {
                return this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: 'Por favor completa todos los campos requeridos (*)',
                });
            }
            this.isLoading = true;
            let oBody = {...this.oFormField, ...this.value}
            const response = await axiosServices.onAxiosPost(this.endpoint, oBody);
            if(response.code === 200) {
                this.isLoading = false;
                let { id, email } = response.data.user;
                return this.$emit('onSaved', {id, email, password: this.oFormField.password});
            } else {
                var alerts = [];
                for (const campo in response.errors) {
                    if (Object.prototype.hasOwnProperty.call(response.errors, campo)) {
                        const mensajes = response.errors[campo];
                        mensajes.forEach(message => {
                            alerts.push(message);
                        });
                    }
                }
                this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: alerts[0]
                });
            }
            this.isLoading = false;
        },
    },
    mounted() {
        this.dinamicFields = this.fields.map(oComponent => {
            return {
                ...oComponent, 
                sEndpointSelect: typeof oComponent.sEndpointSelect == 'object' ? oComponent.sEndpointSelect : (oComponent.sEndpointSelect ? `${oComponent.sEndpointSelect}${oComponent.sDependsOn ? `/${this.oValorField[oComponent.sDependsOn]}` : ''}` : oComponent.sEndpointSelect )
            }
        });
    }
}
</script>