<template>
  <div>
    <q-input filled dense v-model="valor" type="number" :label="`${sLabel}${bRequired ? ' *' : ''}`" :disable="bDisabled" :rules="noVacio" lazy-rules/>
  </div>
</template>

<script>

export default {
  name: "FormNumber",
  emits: ['updateValor'],
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: ''
    },
    sLabel: {
      type: String,
      default: ''
    },
    sVuelidate: {
      type: Boolean,
      default: false
    },
    bDisabled: {
      type: Boolean,
      default: false
    },
    bRequired: {
      type: Boolean,
      default: false
    },
    oValorField: {}
  },
  data() {
    return {
      valor: '',
      pruebaRef: null,
      noVacio: [
        v => !!v,
        v => /^([0-9.])*$/.test(v) || 'Solo se permiten números',
        v => (v >= 0) || 'Solo se permiten números positivos'
      ],
      nameRef: null,
      aItemsData: {}
    }
  },
  mounted() {
    this.aItemsData = this.oValorField;
  },
  watch: {
    valor(newValor) {
      this.$emit('updateValor', newValor)
    },

    value(newValue) {
      this.valor = newValue
    },

    oValorField(newoValorField) {
      this.valor = newoValorField[`${this.sKeyField}`]
    },

    aItemsData(newV) {
      this.valor = newV[`${this.sKeyField}`]
    },
  }
}
</script>