<template>
    <div class="q-mb-sm">
        <q-checkbox v-model="valor" :label="sLabel" />
    </div>
    <!-- <q-dialog    
        v-model="showForm"
        persistent
    >
        <q-card>
            <q-bar>
                <div>Contrato de vinculación</div>
            </q-bar>
            <q-card-section>
                <p>INTERPRETACIÓN</p>
                <p>Para la adecuada interpretación de este contrato, los términos que aparezcan en mayúsculas
                iniciales tendrán el significado que se les atribuye en el presente contrato. Los términos
                definidos en singular incluyen su acepción en plural cuando a ello hubiere lugar, y aquellos
                definidos en género masculino incluyen su acepción en género femenino cuando a ello
                hubiere lugar. Los términos que no sean expresamente definidos deberán entenderse de
                acuerdo con el sentido que les confiera el lenguaje técnico respectivo o por su significado y
                sentido natural y obvio, de conformidad con su uso general</p>
                <p>CONSIDERACIONES PRELIMINARES</p>
                <p>1. La COMERCIALIZADORA 3B S.A.S con NIT 901.422.267-9
                tiene por objeto social la ejecución de operaciones y actos de comercio relacionados con
                actividades organizadas de mercadeo, de promoción o de ventas donde confluyen la
                búsqueda o incorporación de personas naturales con el fin último de vender determinados
                bienes, productos y servicios, el pago o la obtención de compensaciones u otros
                beneficios económicos relacionados directamente con la venta de bienes, productos y
                servicios a través de las personas incorporadas y la coordinación, dentro de una misma
                red comercial, de las personas incorporadas para la respectiva operación o acto de
                comercio de conformidad con lo dispuesto en la Ley 1700 del 2013, el Decreto
                Reglamentario 024 del 12 de enero de 2016 y cualquier norma concordante o
                reglamentaria que aplique. </p>
                <p>2. En desarrollo de su objeto social, la COMERCIALIZADORA 3B ha desplegado
                diferentes estrategias de mercadeo para vincular a los sujetos interesados a su red de
                mercadeo, a través de videos informativos, volantes, charlas y otros medios de
                comunicación que establecen de manera clara y precisa el fin último de las actividades
                comerciales de bienes, productos y servicios a través de la estrategia de mercadeo llamada
                mercadeo en red, y los beneficios para sus VENDEDOR
                INDEPENDIENTE (AFILIADO), dando a conocer de manera previa el plan de
                compensación, el cual hace parte integral de este contrato, en cumplimiento del mandato
                expresado en el artículo 2.2.2.50.2 del Decreto Reglamentario 024 del 12 de enero de
                2016, señalándole de manera clara y categórica que los beneficios económicos a los que
                pueda acceder guardan un relación de causalidad directa con la venta de los bienes,
                productos y servicios objeto de la actividad mercantil de la COMERCIALIZADORA 3B. </p>
                <p>3. El VENDEDOR INDEPENDIENTE (AFILIADO) como consecuencia de la
                información recibida suscribió una declaración de vinculación, de manera libre y
                espontanea, manifestando haber recibido información suficiente y satisfactoria sobre las
                condiciones y la naturaleza jurídica del negocio con la COMERCIALIZADORA 3B.</p>
                <p>4. El VENDEDOR INDEPENDIENTE (AFILIADO), arriba identificado(a), es mayor de
                edad y se encuentra interesado en hacer parte red de mercadeo, siendo
                este un sistema de distribución lícito, organizado por la COMERCIALIZADORA 3B,
                consistente en la creación de una red de mercadeo con 5 niveles de vendedores
                independientes. Los vendedores independientes (afiliados), recomiendan a otros
                vendedores independientes para conformar su primer nivel y cuya distribución será
                fundamentalmente proporcional al volumen de negocio que cada componente haya
                logrado, según el plan de compensación que hace parte integra del presente documento</p>
                <p>5. Para el VENDEDOR INDEPENDIENTE (AFILIADO) es claro que las posibles
                ganancias que pueda obtener al suscribir el contrato como VENDEDOR
                INDEPENDIENTE dependen de su ejercicio como tal, manifestando categóricamente
                que no hay atajos, ni garantía de éxito. Así mismo, comprender que, la utilidad que reciba
                de las ventas según el plan de compensación ya distinguido se liquidara sobre concepto
                de venta neta, es decir que se liquidara el porcentaje que corresponda después de deducir
                gastos como impuestos, descuentos, devoluciones, entre otros.</p>
                <p>6. Así las cosas, las partes declaran que actúan y actuarán, bajo el principio de la buena fe
                en todas las etapas del desarrollo del objeto del presente contrato.</p>
                <p>7. Finalmente, las partes reconocen que de la celebración del presente contrato NO SE
                CONSTITUYE una sociedad de hecho, como tampoco una relación laboral, de modo
                que, tanto el éxito que resulte de la colaboración, como la responsabilidad de cada una de
                las partes en la ejecución de sus obligaciones, es suya propia, en relación con la
                responsabilidad, compromiso y diligencia en el cumplimiento de sus obligaciones
                enmarcadas en el cuerpo de este contrato. </p>
            </q-card-section>
            <q-card-actions>
              <q-btn class="full-width" color="primary" label="Aceptar" @click="onClose"/>
            </q-card-actions>
        </q-card>
    </q-dialog> -->
  </template>
  
  <script>
  
  export default {
    name: "FormText",
    emits: ['updateValor'],
    props: {
      value: {
        default: false
      },
      sKeyField: {
        type: String,
        default: ''
      },
      sLabel: {
        type: String,
        default: ''
      },
      bRequired: {
        type: Boolean,
        default: false
      },
      bDisabled: {
        type: Boolean,
        default: false
      },
      oValorField: {}
    },
    data() {
      return {
        valor: false,
        showForm: false,
        aItemsData: {}
      }
    },
    methods: {
        onClose() {
            this.showForm = false;
        }
    },
    mounted() {
      this.aItemsData = this.oValorField;
    },
    watch: {
      valor(newValor) {
        if(this.valor) this.showForm = true;
        this.$emit('updateValor', newValor)
      },
  
      value(newValue) {
        this.valor = newValue
      },
  
      oValorField(newoValorField) {
        this.valor = newoValorField[`${this.sKeyField}`]
      },
  
      aItemsData(newV) {
        this.valor = newV[`${this.sKeyField}`] ?? false
      },
    }
  }
  </script>