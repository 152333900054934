<template>
  <q-select
    class="q-mb-sm"
    :label="`${sLabel}${bRequired ? ' *' : ''}`"
    :disable="bDisabled"
    dense
    filled 
    v-model="valor"
    :option-value="sValue"
    :option-label="sKey"
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    :autocomplete="false"
    :options="options"
    :loading="loading"
    @filter="filterFn"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          No results
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  props: {
    value: {},
    sLabel: {
      type: String,
      default: ''
    },
    sKeyField: {
      type: String,
      default: ''
    },
    bRequired: {
      type: Boolean,
      default: false
    },
    bDisabled: {
      type: Boolean,
      default: false
    },
    oValorField: {},
    sEndPointSelect: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      filtering: null,
      loading: false,
      valor: this.value,
      sKey: 'name',
      sValue: 'id',
      aItemsData: [],
      options: []
    }
  },
  methods: {
    async onGetData() {
      this.loading = true;
      const response = await axiosServices.onAxiosGet(`${this.sEndPointSelect}`);
      this.aItemsData = response.data;
      if(this.aItemsData.length > 0) {
        if ("code" in this.aItemsData[0]) {
          this.sValue = 'code'
        }

        if ("name" in this.aItemsData[0]) {
          this.sKey = 'name'
        } else {
          this.sKey = 'description'
        }
      }
      this.loading = false;
    },
    filterFn (val, update) {
        if (val.length < 1) {
          update(() => {
            this.options = this.aItemsData
          })
        }

        update(() => {
          const needle = val.toLowerCase()
          this.options = this.aItemsData.filter(v => ("name" in v) ? v.name.toLowerCase().indexOf(needle) > -1 : v.description.toLowerCase().indexOf(needle) > -1)
        })
    }
  },
  mounted() {
    this.onGetData();
  },
  watch: {
    valor(newValor) {
      if(newValor) {
        this.$emit('updateValor', newValor[`${this.sValue}`])
      }
    },
    aItemsData() {
      this.valor = this.aItemsData.find( element => element[`${this.sValue}`] == this.oValorField[`${this.sKeyField}`]) ?? this.value
    },
    sEndPointSelect() {
      this.onGetData();
    }
  }
}
</script>
