<template>
    <q-card class="q-mb-md" v-if="isSearchAble">
        <q-card-section>
            <p>Búsqueda personalizada</p>
            <q-separator></q-separator>
            <q-select dense option-value="id" option-label="name" square outlined v-model="param" :options="searchParams" label="Tipo de búsqueda" />
            <q-input  dense class="q-mt-sm" outlined v-model="search" label="Búsqueda">
                <template v-slot:append>
                <q-icon name="search" />
                </template>
            </q-input>
        </q-card-section>
    </q-card>

    <q-table
        :title="title.toUpperCase()"
        :columns="columns"
        :rows="originalRows"
        :rows-per-page-options="[10, 20, 30]"
        :loading="isLoading"
        v-model:pagination="tablepagination"
        @update:pagination="onChangePerPage"
    >
        <template v-slot:body-cell-edit="props">
            <q-td class="btnsContainers" :props="props" dense>
                <div class="btnsContainers-button">
                <q-btn @click="onGoToUserVision(props.row.id)" icon="visibility" class="btnsContainers-button__general" size="sm" rounded dense flat />
                <q-btn v-if="this.onGetPermission(this.permissions?.edit)" @click="onGoToUserEdit(props.row.id)" icon="edit" class="btnsContainers-button__general" size="sm" rounded dense flat />
                </div>
            </q-td>
        </template>
        <template v-slot:pagination>
            <q-pagination
                color="grey"
                v-model="pagination.page"
                @update:model-value="onGetData"
                :max="pagination.max"
                :disable="isLoading"
                input
            />
        </template>
    </q-table>
</template>
<script>
import translate from "@/services/translate";
import axiosServices from '@/services/axiosServices';
import { mapGetters } from "vuex";
export default {
    name: 'DefaultGrid',
    props: {
        title: {
            type: String,
            default: ''
        },
        endpoint: {
            type: String,
            default: ''
        },
        searchParams: {},
        permissions: {}
    },
    data() {
        return {
            filtering: null,
            isLoading: false,
            isSearchAble: false,
            columns: [],
            originalRows: [],
            tablepagination: {},
            search: '',
            param: '',
            pagination: {
                page: 1,
                max: 1
            },
        }
    },
    methods: {
        onChangePerPage() {
            this.onGetData();
        },
        onGoToUserVision(id) {
            this.$router.replace({name: "vision-usuario", query: { code: id }})
        },
        onGoToUserEdit(id) {
            this.$router.replace({name: "edicion-usuario", params: { id }})
        },
        async onGetData() {
            this.originalRows = [];
            this.isLoading = true;
            let endpoint = `${this.endpoint}?page=${this.pagination.page}&per_page=${this.tablepagination.rowsPerPage}&param=${this.param?.id}&search=${this.search}`;
            const response = await axiosServices.onAxiosGet(endpoint);
            if(!response.data.length) return this.isLoading = false;
            let aColumns = [];
            let aHeaders = Object.keys(response.data[0]);
            aColumns.push({name:"edit", label: "", align: "center", headerStyle: (aHeaders.length <= 5) ? 'width: 30px': ''})
            aHeaders.forEach(sValue => {
                if (sValue.indexOf("id_") !== 0) {
                    let oColumns = {
                        name: sValue,
                        required: true,
                        label: translate.getTranslation(sValue).toLocaleUpperCase(),
                        align: (sValue == 'id') ? 'center' : 'left',
                        field: row => row[`${sValue}`],
                        format: val => `${val}`,
                        sortable: true,
                        classes: (sValue == 'description') ? 'table__td--description' : "",
                        style: "word-wrap: break-word",
                        headerStyle: (sValue == 'id' && aHeaders.length <= 5) ? 'width: 20px' : '',
                        headerClasses: `table__th--${sValue}`
                    }
                    aColumns.push(oColumns);
                }
            })

            this.pagination.page = response.meta.current_page;
            this.pagination.rowsPerPage = response.meta.per_page;
            this.pagination.max = response.meta.last_page;

            this.columns = aColumns;
            this.originalRows = response.data;

            this.isLoading = false;
        },
        onSetSearchParams() {
            if(this.searchParams?.length) {
                this.isSearchAble = true;
                this.param = this.searchParams[0]
            } else {
                this.isSearchAble = false;
            }
        }
    },
    mounted() {
        this.onGetData();
        this.onSetSearchParams();
    },
    watch: {
        search() {
            if(!this.filtering) {
                this.filtering = setTimeout(() => {
                    this.onGetData()
                    clearTimeout(this.filtering);
                    this.filtering = null;
                }, 500);
            }
        }
    },
    computed: {
        ...mapGetters(['onGetPermission'])
    }
}
</script>