<template>
    <q-card class="my-card" flat bordered>
      <q-card-section>
        <q-card-section class="q-pt-xs">
          <div class="text-overline">Mi equipo por niveles</div>
          <div class="text-h5 q-mt-sm q-mb-md">CONSOLIDADO</div>
          <template v-for="value, key in values" :key="key">
            <q-card-section class="text-caption text-grey" horizontal>
              <q-card-section class="col-sm-3 col-5" v-text="numbers[key]"></q-card-section>
              <q-card-section class="col-4" v-text="value"></q-card-section>
            </q-card-section>
            <q-separator v-if="key != values.length - 1" horizontal />
          </template>
        </q-card-section>
      </q-card-section>
    </q-card>
</template>
<script>
export default {
  name: 'ConsolidatedCard',
  props: {
    data: {}
  },
  data() {
    return {
      values: [],
      numbers: [
        'Uno', 'Dos', 'Tres', 'Cuatro', 'Cinco', 'Seis', 'Siete', 'Ocho', 'Nueve', 'Diez'
      ]
    }
  },
  methods: {
    onSetdata() {
      this.values = Object.values(this.data);
    }
  },
  mounted() {
    this.onSetdata();
  },
  watch: {
    data: {
      handler() {
        this.onSetdata()
      }
    }
  }
}
</script>