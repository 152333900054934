<template>
  <div class="container q-mx-md q-my-lg">
    <div class="grid-container q-mb-sm">
      <DashCard
        icon="star"
        title="Estado de afiliación"
        tooltip="Aquí puedo saber si estoy activo para recibir comisiones del programa, si me encuentro inactivo debes realizar una compra de algun producto"
        :num="onGetUser.state"
      />
      <DashCard
        icon="group_add"
        title="Nuevos ingresos"
        tooltip="Este recuadro mostrará el número total de personas que se unieron a tu línea en los últimos 30 días"
        :num="last_month"
      />
      <DashCard
        icon="group"
        title="Total afiliados"
        tooltip="Este recuadro mostrará el número total de personas que están afiliadas a tu línea"
        :num="total"
      />
    </div>
    <div class="grid-container q-mb-sm">
      <ConsolidatedCard :data="consolidated"/>
      <div>
        <q-card class="my-card" flat bordered>
          <q-card-section>
            <q-card-section class="q-pt-xs">
              <div class="text-overline">Compartir mi enlace URL</div>
              <div class="text-h5 q-mt-sm q-mb-md">CÓDIGO {{ onGetUser.id }}</div>
              <q-btn @click="copyURL" outline align="between" class="full-width" color="primary" label="Copiar enlace de afiliación" icon="link" />
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>
<script>
import DashCard from '@/components/General/DashCard';
import ConsolidatedCard from '@/components/General/ConsolidatedCard';
import { mapGetters } from 'vuex';
import axiosServices from '@/services/axiosServices';
export default {
  name: "DashboardPage",
  components: {
    DashCard,
    ConsolidatedCard
  },
  data() {
    return {
      last_month: 0,
      total: 0,
      consolidated: {}
    }
  },
  methods: {
    copyURL() {
      const urlBase = window.location.href.split('/admi')[0];
      navigator.clipboard.writeText(`${urlBase}/agregar/${this.onGetUser.id}`)
        .then(() => {
          this.$q.notify({
            type: 'positive',
            position: 'top-right',
            message: 'URL copiada en el portapapeles'
          });
        })
        .catch(() => {
          this.$q.notify({
            type: 'negative',
            position: 'top-right',
            message: 'URL no pudo ser copiada en el portapapeles'
          });
        });
    },
    async onGetData() {
      const response = await axiosServices.onAxiosGet(`getGeneralData/${this.onGetUser.id}`);
      this.last_month = response.data.last_month;
      this.total = response.data.total;
      this.consolidated = response.data.consolidated;
    }
  },
  mounted() {
    this.onGetData();
  },
  computed: {
      ...mapGetters(['onGetUser'])
  }
}
</script>