<template>
    <q-card flat bordered>
        <q-card-section horizontal>
            <q-card-section class="q-pt-xs">
            <div class="text-overline" v-text="element.category"></div>
            <div class="text-h5 q-mt-sm q-mb-xs" v-text="element.name"></div>
            <div class="text-caption text-grey">
                Adquiere uno de estos productos y activa tu cuenta y genera comisiones.
            </div>
            </q-card-section>

            <q-card-section class="col-5 flex flex-center">
                <q-icon :name="element.icon" size="5rem" />
            </q-card-section>
        </q-card-section>

        <q-separator />

        <q-card-actions align="between">
            <div class="flex items-center">
                <q-icon name="attach_money" size="1.5rem"/>
                <span class="bold text-h6">
                    {{ element.price.toLocaleString('es-ES') }}
                </span>
            </div>
            <q-btn flat color="primary" @click="onShowModal">COMPRAR</q-btn>
        </q-card-actions>
    </q-card>
    <BuyForm 
        :key="nKey"
        :product="element"
        v-model="showModal"
        @onClose="onClose"
    />
</template>
<script>
import BuyForm from '../Form/BuyForm.vue';

export default {
    name: 'ProductCard',
    props: {
        element: {}
    },
    components: {
        BuyForm,
    },
    data() {
        return {
            nKey: 0,
            showModal: false,
        }
    },
    methods: {
        onShowModal() {
            this.nKey += 1;
            this.showModal =  true;
        },
        onClose() {
            this.showModal = false;
        }
    }
}
</script>