<template>
  <q-select
    :label="sLabel"
    dense
    filled 
    v-model="valor"
    :disable="bDisabled"
    option-value="id"
    option-label="name"
    use-input
    hide-selected
    fill-input
    input-debounce="0"
    :options="options"
    :loading="loading"
    @filter="filterFn"
    hint="Digita un carácter para iniciar la búsqueda"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">
          No results
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
export default {
  props: {
    value: {},
    sKeyField: {
      type: String,
      default: ''
    },
    oValorField: {},
    sLabel: {
      type: String,
      default: ''
    },
    sEndPointSelect: {
      type: String,
      default: ''
    },
    bDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      filtering: null,
      loading: false,
      valor: this.value,
      serachVal: '',
      options: []
    }
  },
  methods: {
    async onGetData(sFilter = 'false') {
      this.loading = true;
      const response = await axiosServices.onAxiosGet(`${this.sEndPointSelect}/${sFilter}`);
      this.options = response.data;
      this.loading = false;
    },
    filterFn (val, update, abort) {
        if (val.length < 1) {
          abort()
          return
        }

        update(() => {
          const needle = val.toLowerCase();
          this.serachVal = needle;
          this.loading = true;
          if(!this.filtering) {
            this.filtering = setTimeout(() => {
              this.onGetData(this.serachVal)
              clearTimeout(this.filtering);
              this.filtering = null;
            }, 500);
          }
        })
    }
  },
  mounted() {
    this.onGetData();
    this.valor = this.oValorField[`${this.sKeyField}`];
  },
  watch: {
    valor(newValor) {
      if(newValor) {
        this.$emit('updateValor', newValor.id)
      }
    },
    oValorField: {
      handler() {
        this.valor = this.oValorField[`${this.sKeyField}`];
      },
      deep: true,
    }
  }
}
</script>
