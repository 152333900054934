<template>
    <div class="container q-mx-md q-my-lg">
        <div class="grid-container q-mb-sm">
            <q-card flat bordered>
                <q-card-section>
                    <div class="text-h6 q-mb-md">Ganancias</div>
                    <p>Las ganancias se perderán al final del periodo a no ser que te actives adquiriendo uno de nuestros productos.</p>
                </q-card-section>
            </q-card>
            <div>
                <q-card flat bordered>
                    <q-card-section horizontal>
                        <q-card-section class="col-5 flex flex-center">
                        <q-icon name="payments" size="lg" />
                        </q-card-section>
                        <q-card-section class="q-pt-xs" style="font-size: 1.2rem;">
                        <div class="q-mt-sm q-mb-xs">TOTAL</div>
                        <div class="text-grey">{{ profits.toLocaleString('es-ES') }}</div>
                        </q-card-section>
                    </q-card-section>
                </q-card>
            </div>
        </div>
        <q-table
            class="q-mt-md"
            :columns="columns"
            :rows="originalRows" 
            :loading="isLoading"
            flat
            bordered
        >
            <template v-slot:body-cell-amount="props">
                <td>
                    {{ props.row.amount.toLocaleString('es-ES') }}
                </td>
            </template>
        </q-table>
    </div>
</template>
<script>
import translate from '@/services/translate';
import axiosServices from '@/services/axiosServices';
import { mapGetters } from 'vuex';

export default {
    components: {},
    data() {
        return {
            profits: 0,
            isLoading: false,
            data: [],
        }
    },
    methods: {
        capitalizeWord(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        getTranslation(str) {
            return translate.getTranslation(str)
        },
        async onGetData() {
            this.originalRows = [];
            this.isLoading = true;
            let endpoint = 'wallet/profits'
            const response = await axiosServices.onAxiosGet(endpoint);
            if(!response.data.data.length) return this.isLoading = false;
            let aColumns = [];
            let aHeaders = Object.keys(response.data.data[0]);
            aColumns.push({name:"edit", label: "", align: "center", headerStyle: (aHeaders.length <= 5) ? 'width: 30px': ''})
            aHeaders.forEach(sValue => {
                if (sValue.indexOf("id") !== 0) {
                    let oColumns = {
                        name: sValue,
                        required: true,
                        label: translate.getTranslation(sValue).toLocaleUpperCase(),
                        align: (sValue == 'id') ? 'center' : 'left',
                        field: row => row[`${sValue}`],
                        format: val => `${val}`,
                        sortable: true,
                        classes: (sValue == 'description') ? 'table__td--description' : "",
                        style: "word-wrap: break-word",
                        headerStyle: (sValue == 'id' && aHeaders.length <= 5) ? 'width: 20px' : '',
                        headerClasses: `table__th--${sValue}`
                    }
                    aColumns.push(oColumns);
                }
            })
            this.columns = aColumns;
            this.originalRows = response.data.data;
            this.profits = response.data.profits;
            this.isLoading = false;
        },
    },
    mounted() {
        this.onGetData();
    },
    computed: {
        ...mapGetters(['onGetUser'])
    }
}
</script>