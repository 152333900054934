<template>
    <div>
        <q-card flat bordered>
            <q-card-section>
                <q-card-section class="q-pt-xs" style="font-size: 1.2rem;">
                <div class="q-mt-sm q-mb-xs"><q-icon name="schedule_send"/>TRANFERENCIAS A SOCIOS</div>
                <div class="text-grey">
                    <div class="row justify-between">
                        <div class="col-sm-6 col-12">
                            <q-input filled dense v-model="document" type="number" label="Documento de usuario" lazy-rules/>
                        </div>
                        <div class="col-sm-5 col-12">
                            <q-input filled dense v-model="amount" type="number" label="Cantidad" lazy-rules prefix="$"/>
                        </div>
                    </div>
                </div>
                </q-card-section>
                <q-card-actions>
                    <div class="row full-width justify-between">
                        <div class="col-md-5" v-show="user_was_found">
                            <q-btn class="full-width" color="negative" label="Cancelar" icon="do_disturb" @click="onCancelPerson"></q-btn>
                        </div>
                        <div :class="user_was_found ? 'col-md-5' : 'col-12'">
                            <q-btn class="full-width" color="primary" label="Verificar" icon="assignment_ind" @click="onVerifyPerson" :disable="user_was_found"></q-btn>
                        </div>
                    </div>
                </q-card-actions>
            </q-card-section>
        </q-card>
    
        <q-card class="q-mt-sm" flat bordered v-if="user_was_found">
            <q-card-section>
                <q-input ref="password" square filled clearable v-model="password" :type="passwordFieldType"
                    lazy-rules label="Coloca tu contraseña">
                    <template v-slot:prepend>
                        <q-icon name="lock" />
                    </template>
                    <template v-slot:append>
                        <q-icon :name="visibilityIcon" @click="switchVisibility"
                            class="cursor-pointer" />
                    </template>
                </q-input>


                <p class="q-my-sm" style="font-weight: bold;">Recibe: {{ user?.name }}</p> 
                <q-chip square class="full-width" color="orange" text-color="white" icon-right="warning">Una vez la transacción sea enviada no podra ser reversada</q-chip>
            </q-card-section>
            <q-card-actions>
                <q-btn class="full-width" color="positive" label="Enviar" icon-right="send" :loading="isLoading" @click="onSendMoney" />
            </q-card-actions>
        </q-card>
    </div>
</template>
<script>
import axiosServices from '@/services/axiosServices';

export default {
    name: 'TransferCard',
    data() {
        return {
            isLoading: false,
            user: {},
            user_was_found: false,
            document: '',
            amount: '',
            password: '',
            visibility: false,
            passwordFieldType: 'password',
            visibilityIcon: 'visibility',
        }
    },
    methods: {
        async onVerifyPerson() {
            if(!this.document) 
                return this.$q.notify({ type: 'negative', position: 'top-right', message: 'Digita un documento' });

            if(!this.amount || this.amount < 500) 
                return this.$q.notify({ type: 'negative', position: 'top-right', message: 'Digita una cantidad válida, mayor a $ 500' });

            const response = await axiosServices.onAxiosGet(`wallet/search/${this.document}`);
            if(response.code === 200) {
                this.user = response.data;
                this.user_was_found = true;
            } else { 
                this.user_was_found = false;
                return this.$q.notify({ type: 'negative', position: 'top-right', message: 'Usuario no encontrado' });
            }
        },
        async onSendMoney() {
            this.isLoading = true;
            let oFormField = {
                amount: this.amount,
                password: this.password
            }
            const response = await axiosServices.onAxiosPost(`wallet/transfer/${this.user.code}`, oFormField);
            if(response.code === 200) {
                this.$emit('saveOk')
                this.onCancelPerson();
                this.$q.notify({
                    type: 'positive',
                    position: 'bottom',
                    message: 'Transferencia realizada exitosamente',
                });
            } else {
                this.$q.notify({
                    type: 'negative',
                    position: 'top-right',
                    message: Object.values(response.errors)[0]
                })
            }
            this.isLoading = false;
        },
        onCancelPerson() {
            this.user = {};
            this.user_was_found = false;
        },
        switchVisibility() {
            this.visibility = !this.visibility
            this.passwordFieldType = this.visibility ? 'text' : 'password',
            this.visibilityIcon =  this.visibility ? 'visibility_off' : 'visibility'
        }
    }
}
</script>