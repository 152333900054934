<template>
  <div class="container q-mx-md q-my-lg">
    <ProfileGrid 
      v-if="isLoaded"
      :pages="pages"
      :getEndPoint="getEndPoint"
      :putEndPoint="putEndPoint"
    />
  </div>
</template>
<script>

import ProfileGrid from '@/components/Grid/ProfileGrid.vue'

export default {
  name: "UserEdit",
  components: {
    ProfileGrid
  },
  data() {
    return {
      title: 'Lista de Usuarios',
      getEndPoint: '',
      putEndPoint: '',
      isLoaded: false,
      pages: [
        {
          title: 'Información Personal',
          icon: 'person',
          fields: [
            {
                sComponent: 'FormNumber',
                sNombreCampo: 'card_id',
                sLabel: 'Documento',
                nCols: 12,
                bDisabled: true,
                sEndpointSelect: ''
            },
            {
                sComponent: 'FormText',
                sNombreCampo: 'name',
                sLabel: 'Nombres',
                nCols: 6,
                sEndpointSelect: ''
            },
            {
                sComponent: 'FormText',
                sNombreCampo: 'last_name',
                sLabel: 'Apellidos',
                nCols: 6,
                sEndpointSelect: ''
            },
          ]
        },
        {
          title: 'Información de contacto',
          icon: 'phone',
          fields: [
            {
                sComponent: 'FormText',
                sNombreCampo: 'primary_phone',
                sLabel: 'Teléfono',
                nCols: 12,
                sEndpointSelect: ''
            },
            {
                sComponent: 'FormSelect',
                sNombreCampo: 'country_code',
                sLabel: 'País',
                nCols: 6,
                sEndpointSelect: 'countries',
            },
            {
                sComponent: 'FormSelect',
                sNombreCampo: 'department',
                sLabel: 'Departamento',
                nCols: 6,
                sEndpointSelect: 'departments',
                sDependsOn: 'country_code',
            },
            {
                sComponent: 'FormSelect',
                sNombreCampo: 'id_city',
                sLabel: 'Ciudad',
                nCols: 12,
                sEndpointSelect: 'cities',
                sDependsOn: 'department',
            },
          ]
        },
        {
          title: 'Información de acceso',
          icon: 'lock_open',
          fields: [
            {
                sComponent: 'FormEmail',
                sNombreCampo: 'email',
                sLabel: 'Correo',
                nCols: 12,
                sEndpointSelect: ''
            },
          ]
        }
      ]
    }
  },
  methods: {
    onGetData() {
      this.getEndPoint = `users/${this.$route.params.id}`;
      this.putEndPoint = this.getEndPoint;
      this.isLoaded = true;
    }
  },
  mounted() {
    this.onGetData()
  }
}
</script>