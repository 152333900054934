<template>
    <div class="container q-mx-md q-my-lg">
      <ShipmentGrid
        :title="title"
        :endpoint="endpoint"
        :permissions="permissions"
      />
    </div>
  </template>
  <script>
  import ShipmentGrid from '@/components/Grid/ShipmentGrid.vue'
  
  export default {
    name: "UsersPage",
    components: {
        ShipmentGrid
    },
    data() {
      return {
        title: 'Envíos de Productos',
        endpoint: 'sales',
        permissions: {
          edit: 'users.edit'
        }
      }
    }
  }
  </script>