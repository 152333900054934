<template>
    <q-btn v-if="showBtn" icon="person_add" color="orange" class="full-width" @click="handleShowForm">ASIGNAR PATROCINADOR ADOPTIVO</q-btn>
    <div class="row" v-else>
        <div class="col-md-8 col-12">
            <q-input v-if="!wasFound" dense filled  v-model="valor" type="number" :label="label" lazy-rules />
            <div v-else class="text-h6">Tu patrocinador adoptivo es: <span class="text-negative">{{ sponsor?.name }}</span></div>
        </div>
        <div class="col-md-2 col-12">
            <q-btn v-if="!wasFound" square class="full-width" color="secondary" label="Validar" icon="search" @click="searchAdoptive"/>
        </div>
        <div class="col-md-2 col-12">
            <q-btn square class="full-width" color="negative" label="Cancelar" icon="clear" @click="handleShowForm" />
        </div>

    </div>
</template>
<script>
import axiosServices from '@/services/axiosServices';

export default {
    name: 'FormAdoptiveSponsor',
    data() {
        return {
            showBtn: true,
            wasFound: false,
            valor: '',
            label: 'Busca el código de tu patrocinador adoptivo',
            sponsor: {},
        }
    }, 
    methods: {
        handleShowForm() {
            this.showBtn = !this.showBtn;
        },
        async searchAdoptive() {
            const response = await axiosServices.onAxiosGet(`adoptiveSponsor/${this.valor}`);

            switch (response.code) {
                case 200:
                    if(response.data) {
                        this.wasFound = true;
                        this.sponsor = response.data;
                        this.$emit('updateValor', response.data.code)              
                    } else {
                        this.wasFound = false;
                    }
                    break;
                case 403: 
                    this.$swal.fire({
                        title: '¿Asignar usuario bajo la línea seleccionada?',
                        text: 'Este usuario patrocinador no tiene espacio en su línea directa, será asignado automaticamente a su red bajo un código diferente al seleccionado.',
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        confirmButtonColor: "#26A69A",
                        cancelButtonText: 'Cancelar',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.wasFound = true;
                            this.sponsor = response.errors;
                            this.$emit('updateValor', response.data.code)              
                        } 
                    }).catch(err => console.log(err))
                    break;
                default:
                    this.$q.notify({
                        type: 'negative',
                        position: 'top-right',
                        message: response.message
                    });
                    break;
            }            
        }
    },
    watch: {
        showBtn(isActive) {
            if(!isActive) {
                this.wasFound = false;
                this.$emit('updateValor', null)
            }
        }
    }
}
</script>