<template>
    <div class="container q-mx-md q-my-lg">
        <q-card bordered class="q-mb-sm">
            <q-card-section>
                <div class="row">
                    <RolesGrid classes="col-md-4 col-12 q-px-sm q-mb-md" />
                    <SpecialUserGrid classes="col-md-8 col-12 q-px-sm" />
                </div>
            </q-card-section>
        </q-card>
    </div>
</template>
<script>
import RolesGrid from '../components/Grid/RolesGrid.vue';
import SpecialUserGrid from '../components/Grid/SpecialUserGrid.vue';
export default {
    name: 'PermissionsPage',
    components: {
        RolesGrid,
        SpecialUserGrid
    },
    data() {
        return {

        }
    },
    methods: {
        async onGetRoles() {

        }
    }
}
</script>