<template>
    <div class="container q-mx-md q-my-lg">
        <q-card flat bordered class="q-my-lg" >
            <q-card-section>
                <div class="text-h6">Comprar Productos</div>
            </q-card-section>
        </q-card>
        <div class="grid-container">
            <ProductCard
                v-for="element, key in data" :key="key"
                :element="element"
            />
        </div>
    </div>
</template>
<script>
import ProductCard from '@/components/General/ProductCard.vue';
import axiosServices from '@/services/axiosServices';

export default {
    components: {
        ProductCard
    },
    data() {
        return {
            getEndPoint: 'products',
            data: [],
        }
    },
    methods: {
        async onGetData() {
            const response = await axiosServices.onAxiosGet(this.getEndPoint);
            if(response.code === 200) {
                this.data = response.data;
            }
        }
    },
    mounted() {
        this.onGetData();
    }
}
</script>