<template>
  <div class="container q-mx-md q-my-lg">
    <DefaultGrid
      :title="title"
      :endpoint="endpoint"
      :permissions="permissions"
      :search-params="searchParams"
    />
  </div>
</template>
<script>
import DefaultGrid from '@/components/Grid/DefaultGrid.vue'

export default {
  name: "UsersPage",
  components: {
    DefaultGrid
  },
  data() {
    return {
      title: 'Lista de Usuarios',
      endpoint: 'users',
      searchParams: [
        { id: "id", name: "Código" },
        { id: "card_id", name: "Documento" }
      ],
      permissions: {
        edit: 'users.edit'
      }
    }
  }
}
</script>