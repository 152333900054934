
import 'quasar/dist/quasar.css'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Notify from 'quasar/src/plugins/notify/Notify.js';
import Screen from 'quasar/src/plugins/screen/Screen.js';
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    brand: {
      info: '#2196F3',
      positive: '#4CAF50',
      negative: '#FF5252',
      warning: '#FFC107',
      primary: '#08a9d6'
    }
  },
  plugins: {
    Notify,
    Screen
  },
}