<template>
    <div class="row justify-start q-col-gutter-x-xs">
        <div class="containerModal" :class="`col-xs-12 col-sm-12 col-md-${field.nCols} col-lg-${field.nCols}`" v-for="field, nKey in dinamicFields" :key="nKey">
            <component                
            :is="field.sComponent"
            :sKeyField="field.sNombreCampo"
            :sLabel=field.sLabel
            :bRequired=field.bRequired
            :bDisabled="field.bDisabled"
            :sEndPointSelect="field.sEndpointSelect"
            :oValorField="oValorField"
            @updateValor="onGetValuesForm(field.sNombreCampo, $event)">
            </component>
            <q-separator class="q-my-md" v-if="field.bSeparator" />
        </div>
    </div>
</template>
<script>
// import axiosServices from '@/services/axiosServices.js'

import FormText from '@/components/Fields/FormText'
import FormNumber from '@/components/Fields/FormNumber'
import FormEmail from '@/components/Fields/FormEmail'
import FormPassword from '@/components/Fields/FormPassword'
import FormSelect from '@/components/Fields/FormSelect'
import FormSearchSelect from '@/components/Fields/FormSearchSelect'
import FormChangePassword from '@/components/Fields/FormChangePassword'
export default {
    name: 'ProfileForm',
    components: {
        FormText,
        FormNumber,
        FormEmail,
        FormPassword,
        FormSelect,
        FormSearchSelect,
        FormChangePassword
    },
    props: {
        value: {},
        fields: {},
        oValorField: {},
    },
    data() {
        return {
            bValor: this.value,
            dinamicFields: [],
            oFormField: {}
        }
    },
    methods: {
        onClose() {
            this.$emit("onClose", false)
        },
        onGetValuesForm(index, data) {
            // If data size is set this is file
            if(data && data.size) {
                this.oFormField[index] = data;
            } else {
                this.oFormField[index] = (Array.isArray(data)) ? data : data?.id ? data.id : data
            }
            if(this.dinamicFields.find(oComponent => oComponent.sDependsOn === index)) {
                const nIndex = this.dinamicFields.findIndex(oComponent => oComponent.sDependsOn === index);
                this.dinamicFields[nIndex].sEndpointSelect = `${this.fields[nIndex].sEndpointSelect}/${this.oFormField[index]}`;
            }
            this.$emit('onGetValor', { index, data })
        },
    },
    mounted() {
        this.dinamicFields = this.fields.map(oComponent => {
            return {
                ...oComponent, 
                sEndpointSelect: typeof oComponent.sEndpointSelect == 'object' ? oComponent.sEndpointSelect : (oComponent.sEndpointSelect ? `${oComponent.sEndpointSelect}${oComponent.sDependsOn ? `/${this.oValorField[oComponent.sDependsOn]}` : ''}` : oComponent.sEndpointSelect )
            }
        });
    }
}
</script>