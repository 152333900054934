<template>
    <div :class="classes">
        <q-btn outline @click="onShowForm('new')" class="full-width" color="primary" label="Nuevo Usuario Especial" />
        <div class="text-h6 q-mt-md" v-text="title"></div>
        <div class="row q-mt-md" style="flex-direction: column; gap: .5rem;">
            <div v-if="isLoading">
                CARGANDO.....
            </div>
            <div v-else class="row btn-role justify-between q-px-md" v-for="oData, key in aData" :key="key">
                <div class="col-sm-8 col-12" v-text="oData.name" @click="onShowForm('edit', oData.id)" />
                <div class="col-sm-4 col-12">
                    <q-chip 
                        v-for="role, key in oData.roles" 
                        :key="key" 
                        square 
                        color="orange" 
                        text-color="white" 
                        size="sm" 
                        :label="role.name" 
                    />
                    <q-btn @click="onDelete(oData.id)" flat color="negative" size="sm" icon="delete"></q-btn>
                </div>
            </div>
        </div>
    </div>
    <GenericForm
        :key="nKey"
        :fields="fields"
        :o-valor-field="oValorField"
        :isDisable="isDisable"
        title="REGISTRAR USUARIO ESPECIAL"
        endpoint="specialusers"
        v-model="showForm"
        @onSave="onSave"
        @onClose="closeForm"
    />
</template>
<script>
import axiosServices from '@/services/axiosServices';
import GenericForm from '@/components/Form/GenericForm.vue';
export default {
    name: 'RolesGrid',
    props: {
        classes: {
            type: String,
            default: ''
        }
    },
    components: {
        GenericForm
    },
    data() {
        return {
            nKey: 0,
            title: 'Usuarios Especiales',
            isLoading: false,
            isDisable: false,
            id: null,
            showForm: false,
            showPermission: false,
            aData: [],
            oValorField: {},
            fields: [
                {
                    sComponent: 'FormSearchSelect',
                    sNombreCampo: 'id_user',
                    sLabel: 'Usuario',
                    nCols: 12,
                    sEndpointSelect: 'filterSponsorUser',
                    bRequired: true
                },
                {
                    sComponent: 'FormMultiSelect',
                    sNombreCampo: 'roles',
                    sLabel: 'Roles',
                    nCols: 12,
                    sEndpointSelect: 'roles',
                    bRequired: true
                },
            ]
        }
    },
    methods: {
        onSave() {
            this.onGetData();
        },
        async onShowForm(action, id) {
            if(action === 'edit') {
                this.isDisable = true;
                const response = await axiosServices.onAxiosGet(`specialusers/${id}`);
                this.oValorField = response.data;
                this.fields.map(field => {
                    return field.bDisabled = true;
                })
            } else {
                this.isDisable = false;
                this.oValorField = {}
                this.fields.map(field => {
                    return field.bDisabled = false;
                })
            }
            this.showForm = true;
            this.nKey += 1;
        },  
        closeForm() {
            this.showForm = false;
            this.showPermission = false;
        },
        async onGetData() {
            this.isLoading = true;
            const response = await axiosServices.onAxiosGet('specialusers');
            this.aData = response.data;
            this.isLoading = false;
        },
        async onDelete(id) {
            this.$swal.fire({
                title: '¿Estás seguro?',
                text: 'Una vez confirmes no podrás revertir esta acción',
                showCancelButton: true,
                confirmButtonText: '¡Si, Elimínalo!',
                cancelButtonText: `Cancelar`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await axiosServices.onAxiosDelete(`specialusers/${id}`);
                    if(response.code === 200) this.onGetData();
                } 
            }).catch(err => console.log(err))
            
        }
    },
    mounted() {
       this.onGetData();
    }
}
</script>
<style lang="scss" scoped>
@import '../../styles/RolesGrid.scss';
</style>