const translations = {
    id: "#",
    name: 'Nombre',
    last_name: 'Apellido',
    full_name: 'Nombre Completo',
    country_code: 'País',
    card_id: 'Cédula',
    primary_phone: 'Teléfono',
    adress: 'Dirección',
    email: 'Correo',
    city: 'Ciudad',
    department: 'Departamento',
    created_at: 'Fecha',
    sponsor_user: 'Patrocinador Directo',
    adoptive_sponsor: 'Patrocinador Adoptivo',
    from: 'De',
    for: 'Para',
    amount: 'Cantidad',
    detail: 'Detalle',
    type: 'Tipo transferencia',
    withdrawal: 'Retiro',
    recharge: 'Recarga',
    movement: 'Movimiento',
    buyer: 'Comprador',
    description: 'Descriptor',
    neighborhood: 'Barrio',
    additional_references: 'Referencias adicionales',
    delivered: 'Estado Envío',
    phone: 'Teléfono'
}

export default {
    getTranslation(string) { return (translations[string] ?? string);}
}